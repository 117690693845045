import React, { useEffect, useState } from "react";
import config from "../../../config";
import { Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import DataTable from "../../../components/common/DataTable";
import Filterable from "../../../Filterable";
import useFilter, { AppliedFiltersInterface } from "../../../hooks/useFilter";
import { regulationfilterableFields } from "../../../Filterable/FilterableFields";
import TabHeader from "../../../components/common/TabHeader";
import Skeleton from "react-loading-skeleton";
import { Badge, formatDate } from "../../../components/common/CommonUtils";
import { LinkIcon } from "../../../icons";
import {
  table_view_column,
  details_view_column,
  details_class,
} from "../../../components/common/CommonUtils";
import { useAuthenticatedAxios } from "../../../hooks/useAxios";

const RegulationList = () => {
  const { companyId } = useParams();
  const [activeTab, setActiveTab] = useState("table");
  const [activePage, setActivePage] = useState(1);
  const [selectedRegulation, setSelectedRegulation] = useState<any | null>(
    null
  );
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const axios = useAuthenticatedAxios();

  const [readMore, setReadmore] = useState(false);
  //eslint-disable-next-line
  const [showFilterable, setShowFilterable] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState<
    Array<AppliedFiltersInterface>
  >([
    {
      title: "Title",
      field: "title",
      operator: "contains",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: null,
      columns: 1,
      columnType: "string",
    },
    {
      title: "Country",
      field: "country.name",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Select Country",
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/country-dd",
      searchWithKey: "name",
      isMulti: true,
    },
    {
      title: "Theme",
      field: "theme",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      labelField: "key",
      valueField: "value",
      placeholder: "Select theme",
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/get-regulations-theme",
      isMulti: true,
      searchWithKey: "key",
    },
  ]);

  const {
    loading: useFilterLoading,
    records,
    // selectedFilters,
    sortColumn,
    removeSortColumn,
    resetSorting,
    applyFilters,
    exportRecords,
    addFilter,
    clearFilter,
    removeFilter,
    resetFilter,
    onFilterColumnSelectHandler,
    onFilterOperatorSelectHandler,
    onFilterValueOneChangeHandler,
    onFilterValueTwoChangeHandler,
    updateCursor,
    error,
  } = useFilter({
    endpoint: companyId
      ? config.apiEndpoints.getFilteredRegulations + "?company_id=" + companyId
      : config.apiEndpoints.regulationList,
    exportIdentifier: "Regulations",
    exportEndpoint: "/export/regulations",
    queryParams: [{ page: activePage }],
    selectedFilters: selectedFilters,
    setSelectedFilters: setSelectedFilters,
  });
  useEffect(() => {
    if (companyId) {
      fetchCompany(companyId);
    }
    //eslint-disable-next-line
  }, [companyId]);

  const fetchCompany = async (companyId: string) => {
    const response = await axios.get(
      config.apiEndpoints.getCompanyDetail + "/" + companyId
    );
    const response_data = response?.data;
    let values = response_data?.data?.supply_chain_locations?.map(
      (loc: any) => {
        return { name: loc?.name, id: loc?.id };
      }
    );
    values = response_data?.data?.operation_locations?.map((loc: any) => {
      return { name: loc?.name, id: loc?.id };
    });
    values.push({
      name: response_data?.data?.location?.name,
      id: response_data?.data?.location?.id,
    });
    setSelectedFilters((prev: any) =>
      prev.map((filter: any) => {
        if (filter?.field === "country_id") {
          return { ...filter, ["query_1"]: values };
        }
        return filter;
      })
    );
  };
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  const handleRowClick = (row: any) => {
    setSelectedRegulation(row);
  };
  const handleReadMoreToggle = (rowIndex: number) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };
  const columns = [
    {
      key: "title",
      label: "Title",
      sortable: true,
      width: "30%",
      render: (row: any) => (
        <div>
          {/* Title Link */}
          <div>
            <Link to={row.link} target="_blank" rel="noopener noreferrer">
              <span>{row.title}</span>
            </Link>
          </div>
          {/* Theme, Country, and Texmorny as Badges */}
          <div>
            <div>
              <span>
                {expandedRow === records?.data.indexOf(row)
                  ? row.description
                  : row.description.split(" ").slice(0, 10).join(" ")}
                {row.description.split(" ").length > 10 && (
                  <>
                    <button
                      className="btn btn-link p-0 ms-1"
                      onClick={() =>
                        handleReadMoreToggle(records?.data.indexOf(row))
                      }
                    >
                      {expandedRow === records?.data.indexOf(row)
                        ? "Read Less"
                        : "Read More"}
                    </button>
                  </>
                )}
              </span>
            </div>
            {row?.theme && <Badge text={row.theme} />}
            {row?.taxonomy?.map((item: string, index: number) => (
              <Badge key={index} text={item} />
            ))}{" "}
          </div>
        </div>
      ),
    },
    {
      name: "Issuer Authority",
      render: (row: any) => row?.issuer_authority || "--",
      sortable: true,
      key: "issuer_authority",
      label: "Issuer Authority",
      width: "15%",
    },
    {
      name: "Country",
      render: (row: any) => row?.country?.name || "--",
      sortable: true,
      key: "country_id",
      label: "Country",
      width: "10%",
    },
    {
      name: "Document Type",
      render: (row: any) => row?.document_type || "--",
      sortable: true,
      key: "document_type",
      label: "Document Type",
      // width: "10%",
    },
    {
      name: "Sector Applicability",
      render: (row: any) => row?.sector_applicability || "--",
      sortable: true,
      key: "sector_applicability",
      label: "Sector Applicability",
      width: "15%",
    },
    {
      name: "Issue Date",
      render: (row: any) => formatDate(row?.issued_date) || "--",
      sortable: true,
      key: "issued_date",
      label: "Issue Date",
      // width: "10%",
    },
  ];

  return (
    <>
      <h3>Regulations</h3>
      <section>
        <div className="row">
          <div className="col-12">
            <div className="companies_filter border bg-info-subtle rounded p-4">
              <Filterable
                loading={useFilterLoading}
                canExport={true}
                showFilterable={showFilterable}
                selectedFilters={selectedFilters}
                sortColumn={sortColumn}
                removeSortColumn={removeSortColumn}
                resetSorting={resetSorting}
                filterableFields={regulationfilterableFields}
                sortableFields={[]}
                queryParams={[]}
                exportRecords={exportRecords}
                applyFilters={applyFilters}
                addFilter={addFilter}
                clearFilter={clearFilter}
                removeFilter={removeFilter}
                resetFilter={resetFilter}
                onFilterColumnSelectHandler={onFilterColumnSelectHandler}
                onFilterOperatorSelectHandler={onFilterOperatorSelectHandler}
                onFilterValueOneChangeHandler={onFilterValueOneChangeHandler}
                onFilterValueTwoChangeHandler={onFilterValueTwoChangeHandler}
                error={error}
              />
            </div>
          </div>
        </div>
      </section>

      <TabHeader activeTab={activeTab} onTabChange={setActiveTab} />
      <section>
        {activeTab === "table" && (
          <div className="row">
            <div className={`col-lg-${table_view_column}`}>
              <section className="mt-3 mt-lg-4">
                <div className="row">
                  <div className="col-12">
                    <div className="card card-border mb-4 table_layout2">
                      <div className="position-relative">
                        <DataTable
                          loading={useFilterLoading}
                          data={records?.data || []}
                          columns={columns}
                          isBorderdTable={false}
                          emptyMessage={{ title: "No Regulations Found" }}
                          sortColumn={sortColumn}
                          onRowClick={handleRowClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {config.paginator === "cursor" ? (
                  <div className="pagination">
                    {records?.meta?.has_previous ? (
                      <button
                        onClick={() => updateCursor(records?.prev_cursor)}
                        className="btn btn-primary"
                        style={{ marginRight: "5px" }}
                      >
                        Previous
                      </button>
                    ) : (
                      <button
                        className="btn btn-secondary"
                        disabled
                        style={{ marginRight: "5px" }}
                      >
                        Previous
                      </button>
                    )}
                    {records?.meta?.has_next ? (
                      <button
                        onClick={() => updateCursor(records?.meta?.next_token)}
                        className="btn btn-primary ml-1"
                      >
                        Next
                      </button>
                    ) : (
                      <button className="btn btn-secondary" disabled>
                        Next
                      </button>
                    )}
                  </div>
                ) : (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={records?.total || 0}
                    pageRangeDisplayed={10}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={handlePageChange}
                  />
                )}
              </section>
            </div>

            <div className={`col-lg-${details_view_column} ${details_class}`}>
              <div className="card card-border mt-4">
                <div className="card-header">Detail</div>
                <div className="card-body">
                  {useFilterLoading ? (
                    <Skeleton height={200} width={"100%"} />
                  ) : selectedRegulation ? (
                    <div>
                      <h3>
                        <Link
                          to={selectedRegulation.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>{selectedRegulation.title}</span>
                        </Link>
                      </h3>
                      <p>
                        {readMore
                          ? selectedRegulation?.description
                          : selectedRegulation?.description.substring(0, 200)}
                        ...
                        {selectedRegulation?.description?.length > 200 && (
                          <Link
                            to={""}
                            className="mt-5"
                            onClick={(): void => setReadmore(!readMore)}
                          >
                            {readMore ? "Read Less" : "Read More"}
                          </Link>
                        )}
                      </p>
                    </div>
                  ) : (
                    <p>Select a regulation to see details here.</p>
                  )}
                  <div className="text-end mt-3">
                    <span className="border border-1 border-primary rounded py-1 px-2">
                      <Link
                        title="Link"
                        className="mt-5"
                        to={selectedRegulation?.link || ""}
                        target="_blank"
                      >
                        <LinkIcon />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === "states" && (
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-border mb-4">
                <div className="card-body">
                  <h5>States View</h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
export default RegulationList;
