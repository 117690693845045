import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  PortfolioJobsIcon,
  PortfolioPatentIcon,
  PortfolioRedirectArrowIcon,
  PortfolioRegulationIcon,
  PortfolioSearchIcon,
} from "../../icons";
import Jobs from "../../components/common/CommonJobs";
import Patents from "../../components/common/CommonPatents";
import Regulations from "../../components/common/CommonRegulations";
import News from "../../components/common/CommonNews";
import { useEffect, useState } from "react";
import config from "../../config";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import Skeleton from "react-loading-skeleton";
import {
  currencyToSymbolConversion,
  formatCurrency,
  formatDate,
} from "../../components/common/CommonUtils";
import DataTable from "../../components/common/DataTable";

const Portfolio = () => {
  const navigate = useNavigate();
  const page = 1;
  const axios = useAuthenticatedAxios();
  const [bookmarksData, setBookmarksData] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [entityLoading, setEntityLoading] = useState(true);
  const [companyId, setCompanyId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [entityCounts, setEntityCounts] = useState<any>({});
  const [dealsAndInvestmentDetails, setDealsAndInvestmentDetails] =
    useState<any>("");
  const [dealAndInvestmentLoading, setDealAndInvestmentLoading] =
    useState(false);

  const fetchBookmarks = async (searchQuery = "") => {
    setLoading(true);
    try {
      let url = `${config.apiEndpoints.getBookmarks}?page=${page}&limit=5`;
      if (searchQuery) {
        url += `&entity_title=${encodeURIComponent(searchQuery)}`;
      }
      const response = await axios.get(url);
      setBookmarksData(response?.data?.data || []);
      if (companyId === "" && response?.data?.data?.length > 0) {
        setCompanyId(response?.data?.data[0]?.entity_id);
      }
    } catch (error: any) {
      // toast.error("Error fetching regulations");
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyEntities = async () => {
    setEntityLoading(true);
    try {
      let url = `${config.apiEndpoints.getCompanyEntityCounts}?company_id=${companyId}&entity_names=Patent|Regulation|Job`;

      const response = await axios.get(url);
      const data = response?.data;
      setEntityCounts(data.counts || {});
    } catch (error: any) {
      // toast.error("Error fetching regulations");
    } finally {
      setEntityLoading(false);
    }
  };

  useEffect(() => {
    fetchBookmarks();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchCompanyEntities();
      fetchCompanyInvestmentAndDealsDetails();
    }
    //eslint-disable-next-line
  }, [companyId]);

  const fetchCompanyInvestmentAndDealsDetails = async () => {
    try {
      setDealAndInvestmentLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getDealsAndInvestmentDetails}?company_id=${companyId}&limit=5`
      );
      setDealsAndInvestmentDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setDealAndInvestmentLoading(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value.trim() === "") {
      fetchBookmarks();
    }
  };

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery) {
      fetchBookmarks(searchQuery);
    }
  };

  const handleTagClick = (tag: string) => {
    setSelectedTag(tag);
  };

  const investmentTableColumnsForSorting = [
    {
      label: "Organization Name	",
      key: "organization_identifier.value",
      sortable: false,
      render: (row: any) => row?.organization_identifier?.value,
    },
    {
      label: "Funding Round		",
      key: "identifier",
      sortable: false,
      render: (row: any) => row?.funding_round_identifier?.value,
    },
    {
      label: "Money Raised",
      key: "funding_round_money_raised",
      sortable: false,
      render: (row: any) => {
        const currencySymbol =
          currencyToSymbolConversion(
            row?.funding_round_money_raised?.currency
          ) || "--";
        const value = row?.funding_round_money_raised?.value || "";
        const formattedValue = formatCurrency(value).replace(/^[\D]*/g, "");
        return `${currencySymbol}${formattedValue}`;
      },
    },
    {
      label: "Lead Investor	",
      key: "is_lead_investor",
      sortable: false,
      render: (row: any) =>
        row.is_lead_investor === true
          ? "Yes"
          : row.is_lead_investor === false
          ? "No"
          : "-",
    },
    {
      label: "Announced On		",
      key: "announced_on",
      sortable: true,
      render: (row: any) => formatDate(row?.announced_on),
    },
  ];
  const fundTableColumnsForSorting = [
    {
      label: "Transaction Name",
      key: "identifier",
      sortable: false,
      render: (row: any) => row?.identifier?.value,
    },
    {
      label: "Categories",
      key: "funded_organization_categories",
      sortable: false,
      render: (row: any) => {
        const categories = row?.funded_organization_categories;
        return categories
          ? categories?.map((category: any) => category.value).join(", ")
          : "";
      },
    },
    {
      label: "Number of Investors	",
      key: "num_investors",
      sortable: false,
      render: (row: any) => (row?.num_investors ? row?.num_investors : "--"),
    },
    {
      label: "Money Raised",
      key: "short_description",
      sortable: false,
      render: (row: any) => {
        const description = row?.short_description || "";
        const amountMatch = description.match(/\$([\d,]+)/);
        if (!amountMatch || amountMatch.length < 2) return "-";
        const amount = parseFloat(amountMatch[1].replace(/,/g, ""));
        const formattedAmount = formatCurrency(amount);
        return formattedAmount;
      },
    },
    {
      label: "Lead Investors",
      key: "lead_investor_identifiers",
      sortable: false,
      render: (row: any) =>
        row?.lead_investor_identifiers &&
        row?.lead_investor_identifiers?.map((inv: any) => inv?.value).join(","),
    },
    {
      label: "Announced Date	",
      key: "announced_on",
      sortable: true,
      render: (row: any) => formatDate(row?.announced_on),
    },
  ];

  // const handleRedirect = () => {
  //   navigate("/companies/locations");
  // };

  return (
    <>
      <header className="profile-header">
        <ul className="nav nav-line nav-tabs nav-icon nav-light h-100 d-flex">
          <li className="nav-item">
            <button
              className="d-flex align-items-center nav-link h-100 active"
              onClick={() => navigate("/portfolio")}
            >
              <span className="nav-link-text">Portfolio</span>
            </button>
          </li>
          <li className="nav-item">
            <button
              className="d-flex align-items-center nav-link h-100"
              onClick={() => navigate("/recent-visited")}
            >
              <span className="nav-link-text">Recent Visited</span>
            </button>
          </li>
        </ul>
      </header>

      <section className="my_portfolio">
        <div className="row mt-3">
          <div className="col-lg-9">
            <h1 className="text-black fw-semibold mb-3 h4">My Portfolio</h1>
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-12">
                    <div
                      className="nav flex-column nav-pills border bg-white rounded"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <form className="w-100" onSubmit={handleSearchSubmit}>
                        <div className="search-box">
                          <input
                            className="search-input"
                            type="text"
                            placeholder="Search Here..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <button type="submit" className="search-btn">
                            <PortfolioSearchIcon />
                          </button>
                        </div>
                      </form>
                      {loading ? (
                        [...Array(bookmarksData?.length || 5)]?.map(
                          (_, index) => (
                            <div key={index}>
                              <Skeleton height={40} width={"100%"} />
                            </div>
                          )
                        )
                      ) : bookmarksData?.length === 0 ? (
                        <div className="text-center py-3">
                          No companies found
                        </div>
                      ) : (
                        (() => {
                          let isFirstActiveRendered = false;
                          return bookmarksData?.map(
                            (bookmark: any, index: number) => {
                              const isActive =
                                bookmark?.entity_id === companyId;

                              if (isActive && !isFirstActiveRendered) {
                                isFirstActiveRendered = true;
                                return (
                                  <Link
                                    key={index}
                                    to={`/companies/locations/${companyId}`}
                                    className="nav-link active"
                                  >
                                    <div className="compeny_logo">
                                      {bookmark?.entity_title
                                        ?.charAt(0)
                                        ?.toUpperCase()}
                                    </div>
                                    {bookmark?.entity_title}
                                  </Link>
                                );
                              }

                              return (
                                <button
                                  key={index}
                                  className={`nav-link ${
                                    isActive ? "active" : ""
                                  }`}
                                  onClick={() =>
                                    setCompanyId(bookmark?.entity_id)
                                  }
                                >
                                  <div className="compeny_logo">
                                    {bookmark?.entity_title
                                      ?.charAt(0)
                                      ?.toUpperCase()}
                                  </div>
                                  {bookmark?.entity_title}
                                </button>
                              );
                            }
                          );
                        })()
                      )}
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-12">
                    <div
                      className="tab-content mt-3 mt-md-0"
                      id="v-pills-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                        role="tabpanel"
                        aria-labelledby="myportfolio-tab-1"
                        tabIndex={0}
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              {entityLoading ? (
                                [...Array(3)]?.map((_, index) => (
                                  <div
                                    className="col-12 col-sm-4 col-md-4"
                                    key={index}
                                  >
                                    <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                      <Skeleton height={60} />
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <>
                                  <div className="col-12 col-sm-4 col-md-4">
                                    <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                      <div className="d-flex">
                                        <div className="feature_style_1_icon me-3">
                                          <PortfolioPatentIcon />
                                        </div>
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <div>
                                              <h5 className="text_color_1 fw-bold fs-3 mb-0">
                                                {entityCounts?.Patent || 0}
                                              </h5>
                                            </div>
                                            <div className="text-end ms-2">
                                              <Link
                                                className="dashboard_arow_1"
                                                to={
                                                  entityCounts?.Patent > 0
                                                    ? `/dataset/patent/${companyId}`
                                                    : "/dataset/patent"
                                                }
                                              >
                                                <PortfolioRedirectArrowIcon />
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="border_bottom_custom_1 my-1" />
                                          <p className="fw-medium text-black">
                                            Patents
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4 col-md-4">
                                    <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                      <div className="d-flex">
                                        <div className="feature_style_1_icon me-3">
                                          <PortfolioJobsIcon />
                                        </div>
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <div>
                                              <h5 className="text_color_1 fw-bold fs-3 mb-0">
                                                {entityCounts?.Job || 0}
                                              </h5>
                                            </div>
                                            <div className="text-end ms-2">
                                              <Link
                                                className="dashboard_arow_1"
                                                to={
                                                  entityCounts?.Job > 0
                                                    ? `/dataset/jobs/${companyId}`
                                                    : "/dataset/jobs"
                                                }
                                              >
                                                <PortfolioRedirectArrowIcon />
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="border_bottom_custom_1 my-1" />
                                          <p className="fw-medium text-black">
                                            ESG Jobs
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4 col-md-4">
                                    <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                      <div className="d-flex">
                                        <div className="feature_style_1_icon me-3">
                                          <PortfolioRegulationIcon />
                                        </div>
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <div>
                                              <h5 className="text_color_1 fw-bold fs-3 mb-0">
                                                {entityCounts?.Regulation || 0}
                                              </h5>
                                            </div>
                                            <div className="text-end ms-2">
                                              <Link
                                                className="dashboard_arow_1"
                                                to={
                                                  entityCounts?.Regulation > 0
                                                    ? `/regulations-list/${companyId}`
                                                    : "/regulations-list"
                                                }
                                              >
                                                <PortfolioRedirectArrowIcon />
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="border_bottom_custom_1 my-1" />
                                          <p className="fw-medium text-black">
                                            Regulations
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="row mt-3">
                              <div className="col-lg-12">
                                <div className="border border-bottom-0 bg-white rounded-top p-3">
                                  <h5 className="text-black fw-semibold mb-0">
                                    Tags
                                  </h5>
                                </div>
                                <div className="border bg-white rounded-bottom p-3">
                                  <ul className="d-flex flex-wrap justify-content-between grid gap-3">
                                    {[
                                      "Environment",
                                      "Social",
                                      "Common",
                                      "Governance",
                                    ]?.map((tag, index) => (
                                      <li key={index}>
                                        <Link
                                          to="#"
                                          className="tag_1"
                                          onClick={() => handleTagClick(tag)}
                                        >
                                          {tag}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>

                              <div className="col-lg-12 mt-1">
                                <div className="border border-bottom-0 bg-white rounded-top p-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="text-black fw-semibold mb-0">
                                      Regulations
                                    </h5>
                                    <Link
                                      className="btn btn-sm btn-soft-primary"
                                      to={
                                        entityCounts?.Regulation > 0
                                          ? `/regulations-list/${companyId}`
                                          : "/regulations-list"
                                      }
                                    >
                                      View All
                                    </Link>
                                  </div>
                                </div>
                                <Regulations
                                  taxonomy={selectedTag}
                                  companyId={companyId}
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-lg-12">
                                <div className="border border-bottom-0 bg-white rounded-top p-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="text-black fw-semibold mb-0">
                                      Patents
                                    </h5>
                                    <Link
                                      className="btn btn-sm btn-soft-primary"
                                      to={
                                        entityCounts?.Patent > 0
                                          ? `/dataset/patent/${companyId}`
                                          : "/dataset/patent"
                                      }
                                    >
                                      View All
                                    </Link>
                                  </div>
                                </div>

                                <Patents companyId={companyId} />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-lg-12">
                                <div className="border border-bottom-0 bg-white rounded-top p-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="text-black fw-semibold mb-0">
                                      Jobs
                                    </h5>
                                    <Link
                                      className="btn btn-sm btn-soft-primary"
                                      to={
                                        entityCounts?.Job > 0
                                          ? `/dataset/jobs/${companyId}`
                                          : "/dataset/jobs"
                                      }
                                    >
                                      View All
                                    </Link>
                                  </div>
                                </div>

                                <Jobs companyId={companyId} />
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-lg-12">
                                {/* Deals */}
                                <div className="col-12" id="patents">
                                  <div className="card card-border table_layout2">
                                    <div className="position-relative">
                                      <div className="card-header">
                                        <h4>
                                          <b>Funding Round</b>
                                        </h4>
                                      </div>
                                      <DataTable
                                        loading={dealAndInvestmentLoading}
                                        data={
                                          dealsAndInvestmentDetails?.fund
                                            ?.data || []
                                        }
                                        columns={fundTableColumnsForSorting}
                                        isBorderdTable={false}
                                        emptyMessage={{
                                          title: "No Deals Found",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Investment */}
                                <div className="col-12 mt-5" id="patents">
                                  <div className="card card-border table_layout2">
                                    <div className="position-relative">
                                      <div className="card-header">
                                        <h4>
                                          <b>Investment</b>
                                        </h4>
                                      </div>
                                      <DataTable
                                        loading={dealAndInvestmentLoading}
                                        data={
                                          dealsAndInvestmentDetails?.investment
                                            ?.data || []
                                        }
                                        columns={
                                          investmentTableColumnsForSorting
                                        }
                                        isBorderdTable={false}
                                        emptyMessage={{
                                          title: "No Investment Found",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <h1 className="text-black fw-semibold my-3 mt-lg-0 h4">
              Recent News
            </h1>
            <div className="company-news-section">
              <News companyId={companyId} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
