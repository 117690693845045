import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import getChartOptions from "./ChartOption";

const Charts: React.FC<any> = ({
  title = "",
  type,
  XAxis,
  YAxis,
  plotOptions,
  seriesData,
  showLegend = true,
  pointFormatter,
  showPercentage = false,
  pointStart = "",
  height = "200px",
}) => {
  const chartOptions = getChartOptions(type, {
    title,
    height,
    XAxis,
    YAxis,
    plotOptions,
    pointFormatter,
    seriesData,
    showLegend,
    pointStart,
  });

  const percentageData = seriesData.find(
    (data: any) => data.name !== "Blank"
  )?.y;

  return (
    <div style={{ position: "relative" }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      {showPercentage && percentageData !== undefined && (
        <div
          className="center-label"
          style={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h6 className="text-black fw-semibold">{percentageData} %</h6>
        </div>
      )}
    </div>
  );
};

export default Charts;
