import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { BsPower } from "react-icons/bs";
import { FeatherPersonCircleIcon, FeatherSettingIcon } from "../../icons";
import useAuth from "../../hooks/useAuth";

const LandingHeader = () => {
  const { user, logout } = useAuth();
  return (
    <header>
      <div className="gv_header hz_header_white">
        <div className="container position-relative">
          <div className="row">
            <div className="col-lg-2 col-12 text-left">
              <Link to={"/"} className="d-flex logo align-items-center">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="col-lg-10 col-12 position-static gv_header_menu_res">
              <nav className="navbar navbar-expand-lg position-static">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="nav navbar-nav ms-auto">
                    <li>
                      <Link
                        to={"/"}
                        className="btn btn-primary-horizon-outline"
                        title="Home"
                      >
                        {" Home"}
                      </Link>
                    </li>

                    <div className="dropdown">
                      <Link
                        to={"#"}
                        role="button"
                        data-bs-display="static"
                        data-bs-toggle="dropdown"
                        data-dropdown-animation=""
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        className="btn btn-lg py-2 btn-outline-primary font-s-16 fw-medium rounded-4 ms-3 dropdown-toggle px-1 w-120p nav_user_drop"
                      >
                        Account
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="p-2">
                          <div className="media">
                            <div className="media-head me-2">
                              <div className="avatar avatar-primary avatar-sm avatar-rounded">
                                <span className="initial-wrap">
                                  <FeatherPersonCircleIcon />
                                </span>
                              </div>
                            </div>
                            <div className="media-body">
                              <div className="dropdown d-flex link-dark">
                                Welcome,
                                <br />
                                {user?.firstName} {user?.lastName}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown-divider"></div>
                        <Link
                          to={"/profile?activeTab=manage-profile"}
                          className="dropdown-item"
                          title="Profile"
                        >
                          My Profile
                        </Link>
                        <div className="dropdown-divider"></div>
                        <h6 className="dropdown-header">Manage Account</h6>

                        <Link
                          to={"/profile?activeTab=change-password"}
                          className="dropdown-item"
                          title="Change Password"
                        >
                          <span className="dropdown-icon feather-icon">
                            <FeatherSettingIcon />
                          </span>
                          <span>Change Password</span>
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link
                          to="/login"
                          onClick={logout}
                          className="dropdown-item"
                          role="button"
                          title="Sign Out"
                        >
                          <span className="dropdown-icon feather-icon">
                            <BsPower />
                          </span>
                          <span>Sign Out</span>
                        </Link>
                      </div>
                    </div>
                  </ul>
                </div>
              </nav>
              <div
                id="suggestions"
                style={{ boxShadow: "rgb(136, 136, 136) 5px 10px 10px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingHeader;
