import React, { useEffect, useState } from "react";
import config from "../../config";
import { useParams } from "react-router-dom";
import { useAuthenticatedAxios } from "../../hooks/useAxios";

const PatentDetail = () => {
  const { id = "" } = useParams();
  const axios = useAuthenticatedAxios();
  const [PatentDetails, setPatentDetails] = useState<any>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPatentDetails = async () => {
      try {
        const response = await axios.get(
          `${config.apiEndpoints.getPatentDetail}/${id}`
        );
        setPatentDetails(response.data.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchPatentDetails();
    //eslint-disable-next-line
  }, [id]);

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <h3>
          Patent Name:
          {PatentDetails.patent_title || "Patent name not available"}
        </h3>
      )}
    </>
  );
};
export default PatentDetail;
