import React, { Suspense } from "react";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";

function LandingLayout({ children }: any) {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <LandingHeader />
        {children}
        <LandingFooter />
      </Suspense>
    </>
  );
}

export default LandingLayout;
