import { Options } from "highcharts";

// Utility function to merge objects deeply
const deepMerge = (target: any, source: any): any => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }
  Object.assign(target || {}, source);
  return target;
};

// Common chart options
const commonChartOptions: Options = {
  colors: ["#418c9c", "#233b7d"],
  chart: {
    backgroundColor: "transparent",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
};

// Function to generate chart options
const getChartOptions = (type: string, customOptions: any = {}): Options => {
  let specificOptions: any = {};
  commonChartOptions.title = commonChartOptions.title || { text: "" };

  switch (type) {
    case "area":
      specificOptions = {
        chart: { type: "area", height: customOptions.height || "200px" },
        legend: {
          enabled: customOptions.showLegend,
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
        },
        yAxis: {
          labels: { format: "{value}%" },
          title: { enabled: false },
        },
        tooltip: {
          pointFormat: customOptions.pointFormatter?.(),
          split: true,
        },
        plotOptions: {
          series: { pointStart: customOptions?.pointStart },
          area: {
            stacking: "percent",
            marker: { enabled: false },
          },
        },
        series: customOptions.seriesData,
      };
      break;

    case "column":
      specificOptions = {
        chart: { type: "column", height: customOptions.height },
        legend: {
          enabled: customOptions.showLegend,
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom",
        },
        xAxis: {
          categories: customOptions.XAxis,
          labels: {
            useHTML: true,
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              return this.axis.defaultLabelFormatter.call(this) as string;
            },
          },
          title: { text: customOptions?.title },
        },
        yAxis: {
          labels: {
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              return this.axis.defaultLabelFormatter.call(this) as string;
            },
          },
          title: { text: "" },
        },
        series: customOptions.seriesData,
      };
      break;

    case "line":
      specificOptions = {
        chart: { type: "line" },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
        },
        xAxis: {
          categories: customOptions.XAxis?.categories,
          labels: {
            useHTML: true,
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              return this.axis.defaultLabelFormatter.call(this) as string;
            },
          },
          title: { text: customOptions.XAxis?.title?.text },
        },
        yAxis: {
          labels: {
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              return this.axis.defaultLabelFormatter.call(this) as string;
            },
          },
          title: { text: customOptions.YAxis?.title?.text },
        },
        tooltip: {
          pointFormatter: customOptions.pointFormatter,
        },
        series: structuredClone(customOptions.seriesData),
      };
      break;

    case "pie":
      const totalPercentage = customOptions.seriesData.reduce(
        (acc: number, item: any) => acc + item.y,
        0
      );
      const blankSpace = 100 - totalPercentage;

      if (blankSpace > 0) {
        customOptions.seriesData.push({
          name: "Blank",
          y: blankSpace,
          disableTooltip: true,
          color: "transparent",
        });
      }

      specificOptions = {
        chart: { type: "pie", height: customOptions.height },
        tooltip: {
          formatter: function (this: Highcharts.Point): string | false {
            if ((this as any).disableTooltip) {
              return false;
            }
            return `<b>${this.name}</b>: ${this.percentage?.toFixed()}%`;
          },
        },
        plotOptions: {
          ...customOptions.plotOptions,
          pie: {
            ...customOptions.plotOptions?.pie,
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f}%",
            },
          },
        },
        series: [
          {
            name: "Percentage",
            colorByPoint: true,
            innerSize: "60%",
            data: customOptions.seriesData,
          },
        ],
        drilldown: {
          series: customOptions.drilldownData || [],
        },
      };
      break;

    default:
      throw new Error(`Unsupported chart type: ${type}`);
  }

  return deepMerge(deepMerge({}, commonChartOptions), specificOptions);
};

export default getChartOptions;

export function formatPatentGraphData(data: any) {
  const startYear = data.reduce(
    (min: number, item: any) => Math.min(min, item.year),
    data[0]?.year
  );
  const endYear = data.reduce(
    (max: number, item: any) => Math.max(max, item.year),
    data[0]?.year
  );

  const transformedData = data.reduce(
    (acc: any, { name, year, total }: any) => {
      if (!acc[name]) {
        acc[name] = {};
      }
      acc[name][year] = total;
      return acc;
    },
    {}
  );

  const result = Object.keys(transformedData).map((key) => {
    const yearlyData = [];
    for (let year = startYear; year <= endYear; year++) {
      yearlyData.push(transformedData[key][year] || 0);
    }
    return {
      name: key,
      data: yearlyData,
    };
  });

  return {
    series: result,
    startYear,
    endYear,
  };
}
