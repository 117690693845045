import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import Skeleton from "react-loading-skeleton";
import config from "../../config";

const RecentActivity = () => {
  const axios = useAuthenticatedAxios();
  const [activities, setActivities] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const fetchActivities = async () => {
    setLoading(true);
    try {
      let url = `${config.apiEndpoints.recentVisited}`;
      const response = await axios.get(url);
      setActivities(response.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivities();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="col-lg-4">
      <div className="border bg-white rounded p-4 h-100">
        <div>
          <h5 className="text-black fw-semibold mb-3">Recent Activity</h5>
          {loading ? (
            // Skeleton loading while fetching data
            [...Array(5)].map((_, index) => (
              <div key={index}>
                <Skeleton height={20} className="mb-2" />
                <hr className="my-2" />
              </div>
            ))
          ) : activities.length > 0 ? (
            activities.slice(0, 5).map((activity: any, index: number) => (
              <div key={index}>
                <Link
                  to={`/${
                    activity.type === "Company"
                      ? "company-detail"
                      : "job-detail"
                  }/${activity.entity_id}`}
                  className="text-decoration-none"
                >
                  <p>{activity.title}</p>
                </Link>{" "}
                <hr className="my-2" />
              </div>
            ))
          ) : (
            <p>No recent activities found.</p>
          )}
          <Link to="/recent-visited">
            <button className="btn btn-outline-primary mt-3">
              Go to List View
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecentActivity;
