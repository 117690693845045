const ScoreBlocks = ({ score }: { score: number }) => {
  // Define colors for each threshold
  const colors = ["#f0f0f0", "#fff9c4", "#ffe082", "#ffca28", "#ffb300"];

  const getBlockColor = (index: number) => {
    // Define thresholds for each block (20, 40, 60, 80, 100)
    const thresholds = [20, 40, 60, 80, 100];
    // Check if the score is within the range for the current block index
    if (score <= 20 && index === 0) {
      return colors[0]; // Light yellow color for the first block
    }
    if (score > thresholds[index]) {
      return colors[index]; // If score exceeds the threshold, fill the block with the respective color
    }
    // If score is between thresholds[index - 1] and thresholds[index], fill the block partially
    if (
      index > 0 &&
      score <= thresholds[index] &&
      score > thresholds[index - 1]
    ) {
      return colors[index];
    }
    return "white";
  };

  const getScoreRangeText = () => {
    if (score < 10) {
      return "Negligible";
    } else if (score >= 10 && score <= 20) {
      return "Low";
    } else if (score > 20 && score <= 30) {
      return "Medium";
    } else if (score > 30 && score <= 40) {
      return "High";
    } else {
      return "Severe";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* Score Display */}
      <div
        style={{ fontWeight: "bold", flexShrink: 0, width: "25px" }}
        className=""
      >
        {score}
      </div>

      {/* Score Blocks */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          width: "50%",
          height: "50px",
        }}
        className="mx-3"
      >
        {[0, 1, 2, 3, 4]?.map((blockIndex) => {
          const blockColor = getBlockColor(blockIndex);
          return (
            <div
              key={blockIndex}
              style={{
                backgroundColor: blockColor,
              }}
              className={
                blockColor === "white" ? "border border-start-0" : "none"
              }
            />
          );
        })}
      </div>

      {/* Score Range Text */}
      <div style={{ fontWeight: "bold", flexShrink: 0 }}>
        {getScoreRangeText()}
      </div>
    </div>
  );
};

export default ScoreBlocks;
