import { Navigate, useOutlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { HelmetProvider } from "react-helmet-async";

export const DefaultLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="hk-wrapper">
        <div className="container-fluid">
          <HelmetProvider>{outlet}</HelmetProvider>
        </div>
      </div>
    </>
  );
};
