import React, { Fragment, useEffect, useRef, useState } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import "./style.scss";
import {
  AppliedFiltersInterface,
  FilterableFieldsGroupInterface,
  SortableFieldsInterface,
} from "../hooks/useFilter";
import {
  // FaFileExport,
  FaFilter,
  //  FaPlus
  FaSyncAlt,
} from "react-icons/fa";
import moment from "moment";
import { DropdownComponent } from "../components/common/DropdownComponent";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css";
import { formatDate } from "../components/common/CommonUtils";

export interface FilterableProps {
  sortableFields?: Array<SortableFieldsInterface>;
  filterableFields?: Array<FilterableFieldsGroupInterface>;
  queryParams: Array<Record<string, any>>;
  showFilterable: boolean;
  canExport: boolean;
  loading: boolean;
  exportRecords?: () => void;
  applyFilters: () => void;
  selectedFilters: Array<AppliedFiltersInterface>;
  sortColumn: (column: string) => void;
  removeSortColumn: (column: string) => void;
  resetSorting: () => void;
  addFilter?: () => void;
  clearFilter?: () => void;
  removeFilter?: (value: AppliedFiltersInterface) => void;
  resetFilter: () => void;
  onFilterColumnSelectHandler?: (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onFilterOperatorSelectHandler?: (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onFilterValueOneChangeHandler: (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    searchWithKey?: string
  ) => void;
  onFilterValueTwoChangeHandler: (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void;
  error?: { field?: string; type: string; error: string };
}

const Filterable: React.FC<FilterableProps> = (props) => {
  const {
    // filterableFields,
    showFilterable,
    // canExport,
    // exportRecords,
    loading,
    selectedFilters,
    applyFilters,
    // addFilter,
    // clearFilter,
    // removeFilter,
    resetFilter,
    // onFilterColumnSelectHandler,
    // onFilterOperatorSelectHandler,
    onFilterValueOneChangeHandler,
    onFilterValueTwoChangeHandler,
    error,
  } = props;
  const dateRangePickerRef = useRef<any>(null);
  //eslint-disable-next-line
  const [queryValue, setQueryValue] = useState<any>("");
  const [showPicker, setShowPicker] = useState(false);

  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
    key: string;
  }>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelectChange = (q: string) => {
    setQueryValue(q);
  };

  const handleDateRangeChange = (
    ranges: any,
    filter: AppliedFiltersInterface,
    index: number
  ) => {
    const { selection } = ranges;
    setDateRange(selection);
    const startDate = moment(selection.startDate).toISOString();
    const endDate = moment(selection.endDate).toISOString();

    // Assuming onFilterValueOneChangeHandler handles query_1 and query_2 updates
    onFilterValueOneChangeHandler({ ...filter, query_1: startDate }, index, {
      target: { value: startDate },
    } as React.ChangeEvent<HTMLInputElement>);
    onFilterValueTwoChangeHandler({ ...filter, query_2: endDate }, index, {
      target: { value: endDate },
    } as React.ChangeEvent<HTMLInputElement>);
    setShowPicker(false);
  };

  useEffect(() => {
    // Check if f.query_1 and f.query_2 are null or undefined, and reset date range
    const resetDateRangeIfNeeded = () => {
      selectedFilters?.forEach((f) => {
        if (f?.columnType === "datetime" && !f.query_1) {
          setDateRange({
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          });
        }
      });
    };

    resetDateRangeIfNeeded();
  }, [resetFilter, selectedFilters]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dateRangePickerRef.current &&
        !dateRangePickerRef.current.contains(event.target)
      ) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="filterable">
        {showFilterable && (
          <div className="filters">
            <Row>
              <div className="col-12">
                <div className="row">
                  <div className="row filters filter-conditions">
                    {selectedFilters.map((f, index) => (
                      <Fragment key={index}>
                        {/* <div className="filters-column float-start">
                        <select
                          className="form-select"
                          onChange={(e) =>
                            onFilterColumnSelectHandler(f, index, e)
                          }
                        >
                          <>
                            <option value="">Select Filter</option>
                            {filterableFields?.map((group, gk) => {
                              return (
                                <>
                                  <optgroup
                                    label={group.title}
                                    key={`group-${index}-${gk}`}
                                  >
                                    {group.fields.map((field, fk) => (
                                      <option
                                        value={JSON.stringify(field)}
                                        key={`group-${index}-${gk}-${fk}`}
                                      >
                                        {field.label}
                                      </option>
                                    ))}
                                  </optgroup>
                                </>
                              );
                            })}
                          </>
                        </select>
                        <span className="text-danger">{error?.type === 'filter' && error?.error}</span>

                      </div> */}
                        {/* <div className="filters-operator float-start">
                        <select
                          className="form-select"
                          onChange={(e) =>
                            onFilterOperatorSelectHandler(f, index, e)
                          }
                        >
                          <>
                            <option value="">Select Operator</option>
                            {f.operators.map((operator, ok) => (
                              <option
                                value={JSON.stringify(operator)}
                                key={`group-${f.field}-${ok}`}
                              >
                                {operator.label}
                              </option>
                            ))}
                          </>
                        </select>
                      </div> */}

                        {f.columns === 0 && (
                          <>
                            <div className="filters-empty float-start"></div>
                          </>
                        )}
                        {f.columns === 1 && (
                          <div
                            className="filters-item mb-2 col-lg-3 col-md-6 col-sm-6 col-12 "
                            key={`filter-${index}`}
                          >
                            <div className="filters-full float-start">
                              <label className="fw-semibold text-black">
                                {f.title}
                              </label>
                              {f.columnType === "string" && (
                                <input
                                  onChange={(e) =>
                                    onFilterValueOneChangeHandler(f, index, e)
                                  }
                                  type="text"
                                  value={f?.query_1 || ""}
                                  className="form-control"
                                  placeholder={f.placeholder}
                                />
                              )}
                              {f.columnType === "number" && (
                                <input
                                  onChange={(e) =>
                                    onFilterValueOneChangeHandler(f, index, e)
                                  }
                                  type="number"
                                  value={f?.query_1 || ""}
                                  className="form-control"
                                  placeholder={f.placeholder}
                                />
                              )}
                              {f.columnType === "datetime" && (
                                <>
                                  <input
                                    type="text"
                                    placeholder={f?.placeholder}
                                    defaultValue={
                                      f?.query_1 && f?.query_2
                                        ? `${formatDate(
                                            f.query_1
                                          )} - ${formatDate(f.query_2)}`
                                        : ""
                                    }
                                    onClick={() => setShowPicker(!showPicker)} // Open picker on click
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "0.1rem",
                                    }}
                                    className="form-control"
                                  />

                                  {showPicker && (
                                    <div
                                      ref={dateRangePickerRef}
                                      style={{
                                        position: "absolute",
                                        top: "50px",
                                        zIndex: 1000,
                                        boxShadow:
                                          "0 4px 6px rgba(0, 0, 0, 0.1)",
                                      }}
                                      className="mt-2"
                                    >
                                      <DateRangePicker
                                        ranges={[dateRange]}
                                        onChange={(ranges) =>
                                          handleDateRangeChange(
                                            ranges,
                                            f,
                                            index
                                          )
                                        }
                                        moveRangeOnFirstSelection={false}
                                        editableDateInputs={true}
                                        staticRanges={[]}
                                        inputRanges={[]}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                              {f.columnType === "autocomplete" && (
                                <DropdownComponent
                                  searchBy={f?.searchWithKey}
                                  valueField={f?.valueField}
                                  labelField={f?.labelField}
                                  isMulti={f?.isMulti || false}
                                  label={f?.title || ""}
                                  name={f?.field}
                                  value={f?.query_1 ? f?.query_1 : []}
                                  searchUrl={f?.searchUrl || ""}
                                  isloading={props?.loading}
                                  inputChange={(val: string) => {
                                    handleSelectChange(val);
                                  }}
                                  handleChange={(e: any) =>
                                    onFilterValueOneChangeHandler(f, index, e)
                                  }
                                />
                              )}
                              {f.columnType === "select" && (
                                <select
                                  onChange={(e) =>
                                    onFilterValueOneChangeHandler(f, index, e)
                                  }
                                  value={f.query_1 || ""}
                                  className="form-select"
                                >
                                  <option value="">{f.placeholder}</option>
                                  {f.options?.map(
                                    (option: any, optIndex: number) => (
                                      <option
                                        key={optIndex}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              )}
                              {f.columnType === "toggle" && (
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label={f.placeholder}
                                  value={f?.query_1 || ""}
                                  onChange={(e) =>
                                    onFilterValueOneChangeHandler(f, index, e)
                                  }
                                />
                              )}
                              <span className="text-danger">
                                {error?.type === "input" &&
                                  error?.field === f?.field &&
                                  error?.error}
                              </span>
                            </div>
                          </div>
                        )}
                        {f.columns === 2 && (
                          <div
                            className="filters-item mb-2 col-lg-6 col-md-12 col-sm-12 col-12 "
                            key={`filter-${index}`}
                          >
                            <div className="filters-query_1 float-start">
                              <label className="fw-semibold text-black">
                                {f.title}
                              </label>
                              {f.columnType === "string" && (
                                <input
                                  onChange={(e) =>
                                    onFilterValueOneChangeHandler(f, index, e)
                                  }
                                  type="text"
                                  value={f?.query_1 || ""}
                                  className="form-control"
                                  placeholder={f.placeholder}
                                />
                              )}
                              {f.columnType === "number" && (
                                <input
                                  onChange={(e) =>
                                    onFilterValueOneChangeHandler(f, index, e)
                                  }
                                  type="number"
                                  className="form-control"
                                  value={f?.query_1 || ""}
                                  placeholder={f.placeholder}
                                />
                              )}
                              {/* {f.columnType === "datetime" && (
                                <input
                                  onChange={(e) =>
                                    onFilterValueOneChangeHandler(f, index, e)
                                  }
                                  type="datetime-local"
                                  step="1"
                                  className="form-control"
                                  defaultValue={
                                    f?.query_1
                                      ? moment(f?.query_1).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                      : ""
                                  }
                                  placeholder={f.placeholder}
                                />
                              )} */}
                              {f.columnType === "toggle" && (
                                <select
                                  onChange={(e) =>
                                    onFilterValueOneChangeHandler(f, index, e)
                                  }
                                  value={f?.query_1 || ""}
                                  className="form-select"
                                >
                                  <option value="">{f.placeholder}</option>
                                  <option value="1">True/Yes</option>
                                  <option value="0">False/No</option>
                                </select>
                              )}
                              <span className="text-danger">
                                {error?.type === "input" &&
                                  error?.field === f?.field &&
                                  error?.error}
                              </span>
                            </div>
                            <div className="filters-query_2 float-start">
                              <label className="fw-semibold text-black"></label>
                              {f.columnType === "string" && (
                                <input
                                  onChange={(e) =>
                                    onFilterValueTwoChangeHandler(f, index, e)
                                  }
                                  type="text"
                                  value={f?.query_2 || ""}
                                  className="form-control"
                                  placeholder={f.placeholder}
                                />
                              )}
                              {f.columnType === "number" && (
                                <input
                                  onChange={(e) =>
                                    onFilterValueTwoChangeHandler(f, index, e)
                                  }
                                  type="number"
                                  value={f?.query_2 || ""}
                                  className="form-control"
                                  placeholder={f.placeholder}
                                />
                              )}
                              {/* {f.columnType === "datetime" && (
                                <input
                                  onChange={(e) =>
                                    onFilterValueTwoChangeHandler(f, index, e)
                                  }
                                  type="datetime-local"
                                  step="1"
                                  defaultValue={
                                    f?.query_2
                                      ? moment(f?.query_2).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                      : ""
                                  }
                                  className="form-control"
                                  placeholder={f.placeholder}
                                />
                              )} */}
                              {f.columnType === "toggle" && (
                                <select
                                  onChange={(e) =>
                                    onFilterValueTwoChangeHandler(f, index, e)
                                  }
                                  className="form-select"
                                  value={f?.query_2 || ""}
                                >
                                  <option value="">{f.placeholder}</option>
                                  <option value="1">True/Yes</option>
                                  <option value="0">False/No</option>
                                </select>
                              )}
                              <span className="text-danger">
                                {error?.type === "operator" && error?.error}
                              </span>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>
                <div className="filters-control">
                  {/* <div className="filters-control-item">
                    <button
                      title={"Add New Filter"}
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      disabled={loading}
                      onClick={(e) => addFilter()}
                    >
                      <FaPlus />
                    </button>
                  </div> */}
                  {selectedFilters && (
                    <>
                      {selectedFilters?.length > 0 && (
                        <div className="filters-control-item">
                          <div className="filters-control-item-line"></div>
                          <button
                            title={"Remove Filters"}
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            disabled={loading}
                            onClick={(e) => resetFilter()}
                          >
                            <FaSyncAlt />
                          </button>
                        </div>
                      )}
                      <div className="filters-control-item">
                        <div className="filters-control-item-line"></div>
                        <button
                          title={"Filter Records"}
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                          disabled={loading}
                          onClick={(e) => {
                            applyFilters();
                          }}
                        >
                          {loading && <Spinner size={"sm"}></Spinner>}
                          {!loading && <FaFilter />}
                        </button>
                      </div>
                      {/* {exportRecords && canExport && (
                        <div className="filters-control-item">
                          <div className="filters-control-item-line"></div>
                          <button
                            title={"Export Filtered Records"}
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            disabled={loading}
                            onClick={(e) => {
                              exportRecords();
                            }}
                          >
                            {loading && <Spinner size={"sm"}></Spinner>}
                            {!loading && <FaFileExport />}
                          </button>
                        </div>
                      )} */}
                    </>
                  )}
                </div>
              </div>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default Filterable;
