import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../../assets/img/logo.png";
import AuthRightContent from "../../layouts/AuthRightContent";
import { Link } from "react-router-dom";
import config from "../../config";
import { useAxios } from "../../hooks/useAxios";
import SignUpOtpModal from "./SignUpOtpModal";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignUp = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({});
  const axios = useAxios();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      setErrorMessage("");
      try {
        try {
          const response = await axios.post(config.apiEndpoints.signUp, values);
          if (response?.data && response?.data?.status === 201) {
            setUser(response?.data?.user);
            toast.success(response?.data?.message);
            setShowModal(true);
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
        } finally {
          setIsSubmitting(false);
        }
      } catch (error) {
        setErrorMessage("Error during sign up. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="row align-items-center sign-up1-sec">
        <div className="col-xl-4 col-lg-5 col-md-6 position-relative px-0">
          <div
            className="bg-white align-content-center h-735p"
            style={{ minHeight: 735 }}
          >
            <div className="pt-0 px-lg-7 px-5 pb-5">
              <div className="text-center mb-6">
                <a className="navbar-brand" title="NeoImpact" href="/">
                  <img
                    className="brand-img d-inline-block"
                    src={logo}
                    width={144}
                    height={36}
                    alt="NeoImpact"
                    loading="lazy"
                  />
                </a>
              </div>
              <div className="text-center mb-4">
                <h3 className="my-0 fw-semibold">
                  Create your Neo Impact account
                </h3>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        className="form-control signup-hover"
                        placeholder="First Name *"
                        type="text"
                        id="firstName"
                        required
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label>First Name</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        className="form-control signup-hover"
                        placeholder="Last Name *"
                        type="text"
                        id="lastName"
                        required
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label>Last Name</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-floating mb-4">
                      <input
                        className="form-control signup-hover"
                        placeholder="Business Email Address *"
                        type="text"
                        id="email"
                        required
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label>Business Email Address</label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-floating mb-4">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        required
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <Link
                        to="#"
                        onClick={handleTogglePassword}
                        className="input-suffix text-muted"
                        title={showPassword ? "Hide Password" : "View Password"}
                        style={{
                          position: "absolute",
                          top: "16px",
                          right: "5px",
                        }}
                      >
                        {formik.values.password !== "" &&
                          (showPassword ? <FaEyeSlash /> : <FaEye />)}
                      </Link>
                      <label>Password</label>
                    </div>
                  </div>
                </div>
                {errorMessage && (
                  <div className="text-danger">{errorMessage}</div>
                )}

                <button
                  type="submit"
                  className="btn btn-primary-horizon btn-lg w-100 text-none mt-4 mb-4 ms-0 me-0"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Creating Account..." : "Create free account"}
                </button>
                <p className="text-center">
                  Already have an account?{" "}
                  <Link to="/login" className="text-decoration-underline">
                    Sign in
                  </Link>{" "}
                  here.
                </p>
              </form>
            </div>
          </div>
        </div>
        <AuthRightContent />
      </div>
      <SignUpOtpModal
        show={showModal}
        onHide={() => setShowModal(false)}
        user={user}
      />
    </>
  );
};

export default SignUp;
