import React, { useState, useEffect } from "react";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import config from "../../config";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
interface companyNewsProps {
  companyId: string;
}

const News = ({ companyId }: companyNewsProps) => {
  const axios = useAuthenticatedAxios();
  const [newsData, setNewsData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const length = newsData?.data?.length || 10;

  const fetchNews = async () => {
    setLoading(true);
    try {
      let url = `${config.apiEndpoints.getCompanyNews}?company_id=${companyId}`;

      const response = await axios.get(url);
      setNewsData(response?.data?.data?.data || []);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchNews();
    }

    //eslint-disable-next-line
  }, [companyId]);

  return (
    <div className="row">
      <div className="col-sm-6 col-12 col-lg-12">
        <div className="border bg-info bg-opacity-10 rounded p-3">
          {loading ? (
            <div>
              {[...Array(length)].map((_, index) => (
                <div key={index} className="mb-3">
                  <Skeleton height={20} width={250} className="mb-2" />
                  <Skeleton height={20} width={250} className="mb-2" />
                </div>
              ))}
            </div>
          ) : (
            <div>
              {newsData?.length > 0 ? (
                newsData?.map((news: any, index: number) => (
                  <Link
                    to={news?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h6
                      key={index}
                      className="fw-medium text-black border-bottom border-secondary-subtle py-2 mb-0"
                    >
                      {news?.title || "No title available"}{" "}
                      {/* Default to title */}
                    </h6>
                  </Link>
                ))
              ) : (
                <p>No news found.</p>
              )}
            </div>
          )}

          <div className="text-end mt-3">
            <Link
              to={
                newsData.length > 0
                  ? `/dataset/news/${companyId}`
                  : "/dataset/news"
              }
              className="text-decoration-underline fw-semibold"
            >
              Show More news
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
