import React, { useState, useEffect } from "react";
import { useAuthenticatedAxios } from "../../hooks/useAxios"; // Custom Axios hook
import config from "../../config";
import { PortfolioLinkRoundArrowIcon } from "../../icons";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

interface regulationsProps {
  companyId: string;
}

const Jobs = ({ companyId }: regulationsProps) => {
  const axios = useAuthenticatedAxios();
  const [jobsData, setJobsData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const page = 1;
  const length = jobsData?.data?.length || 5;

  const fetchJobs = async () => {
    setLoading(true);
    try {
      let url = `${config.apiEndpoints.getFilteredJobs}?page=${page}&limit=5&company_id=${companyId}`;
      const response = await axios.get(url);
      setJobsData(response?.data?.data || []);
    } catch (error: any) {
      // toast.error("Error fetching regulations");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchJobs();
    }
    //eslint-disable-next-line
  }, [companyId]);

  return (
    <div className="row">
      <div className="col-sm-6 col-12 col-lg-12">
        <div className="style_2 border bg-white rounded-bottom">
          <div className="table-responsive">
            <table className="table mb-0">
              <tbody>
                {loading ? (
                  [...Array(length)].map((_, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton height={20} width={500} className="mb-2" />
                      </td>
                      <td>
                        <Skeleton height={20} width={30} className="mb-2" />
                      </td>
                    </tr>
                  ))
                ) : jobsData.length > 0 ? (
                  jobsData?.map((job: any, index: number) => (
                    <tr key={index}>
                      <td>{job?.job_title || "No title available"}</td>
                      <td>
                        <div className="text-end">
                          <Link
                            className="dashboard_arow_2"
                            to={job?.job_apply_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <PortfolioLinkRoundArrowIcon />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>No jobs found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="text-end mt-3">
          <Link
            to="#"
            className="text-decoration-underline fw-semibold"
            onClick={() => fetchRegulations("filterKey", "filterValue")}
          >
            Show More Regulations
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Jobs;
