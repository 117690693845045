import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import logo from "../../assets/img/logo.png";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import check_png from "../../assets/img/check.svg";
import { useAxios } from "../../hooks/useAxios";
import config from "../../config";

const SignUpOtpModal = ({
  show,
  onHide,
  user,
}: {
  show: boolean;
  onHide: () => void;
  user: any;
}) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sendAccessCode, setSendAccessCode] = useState(true);
  const [sendAccessLink, setSendAccessLink] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendAccessLink, setResendAccessLink] = useState(false);
  const [resendAccessCode, setResendAccessCode] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const axios = useAxios();

  const verifyAccessCode = async () => {
    if (otp === "") {
      toast.error("Please enter the OTP ");
      return;
    }
    let formData = {
      email: user?.email,
      otp: otp,
    };
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        config.apiEndpoints.verifyOtp,
        formData
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        navigate("/login");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSendAccess = () => {
    if (sendAccessCode) {
      verifyAccessCode();
    } else {
      handleSendAccessCodeLink("resend-otp");
    }
    return;
  };

  const handleSendAccessCodeLink = async (type = "resend-otp") => {
    if (type === "resend-email" || type === "resend-otp") {
      setResendLoading(true);
    }

    let formData = {
      email: user?.email,
      type: type,
    };
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        config.apiEndpoints.resendOtp,
        formData
      );
      if (type === "resend-email") {
        if (sendAccessLink) {
          setResendAccessLink(true);
          setResendLoading(false);
        }
        setSendAccessLink(true);
        setSendAccessCode(false);
        setResendAccessCode(false);
      } else if (type === "resend-otp") {
        if (sendAccessCode) {
          setResendAccessCode(true);
          setResendLoading(false);
        }
        setSendAccessCode(true);
        setSendAccessLink(false);
        setResendAccessLink(false);
      }
      toast.success(response?.data?.message);
    } catch (error: any) {
      toast.error("Something went wrong! Try after sometime.");
      setResendLoading(false);
    } finally {
      setIsSubmitting(false);
    }

    if (type === "resend-email" || type === "resend-code") {
      setResendLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        dialogClassName="mx-auto modal-dialog-centered modal-lg"
      >
        <button
          id="SUFCloseBtn"
          onClick={onHide}
          type="button"
          className="btn-close btn-close-corner-normal"
          aria-label="Close"
          title="Close"
        ></button>
        <div className="row m-lg-0">
          <div className="col-lg-7 px-lg-0">
            <div className="p-5">
              {/* <AccessForm /> */}
              <div className="form-label-group mb-4">
                <h4 className="my-0">Get Access to your account</h4>
              </div>
              <div className="row gx-3"></div>

              {sendAccessCode ? (
                <div className="text-center">
                  <p>An email with the code has been sent to</p>
                  <p className="mb-2">{user?.email}</p>
                  <div className="col-lg-12">
                    <div className="my-4">
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={5}
                        renderSeparator={<span className="mx-2">-</span>}
                        renderInput={(props: any) => <input {...props} />}
                        inputStyle={"otp_input fs-3"}
                        containerStyle={"d-inline"}
                      />
                    </div>
                  </div>
                  {resendAccessCode ? (
                    <p className="mb-2 text-success"> Sent! </p>
                  ) : (
                    <p className="mb-2">
                      <Link
                        to="#"
                        role="button"
                        className="link"
                        onClick={() => handleSendAccessCodeLink("resend-otp")}
                      >
                        {" "}
                        {resendLoading ? (
                          <Spinner />
                        ) : (
                          "Did not get the code?"
                        )}{" "}
                      </Link>
                    </p>
                  )}
                </div>
              ) : (
                <>
                  {" "}
                  {sendAccessLink ? (
                    <div className="card card-body text-center">
                      <img
                        className="align-self-center my-2"
                        src={check_png}
                        width={20}
                        height={20}
                        alt="check-img"
                      />
                      <p>Please check your email.</p>
                      <p>We've sent you a link to login.</p>
                      {resendAccessLink ? (
                        <p className="text-success"> Sent! </p>
                      ) : (
                        <p className="my-2">
                          <Link
                            to="#"
                            role="button"
                            className="link"
                            onClick={() =>
                              handleSendAccessCodeLink("resend-email")
                            }
                          >
                            {resendLoading ? <Spinner /> : "Resend"}
                          </Link>
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="col-lg-12">
                      <div className="form-floating mb-4">
                        <input
                          className="form-control"
                          placeholder="Business Email Address*"
                          value={user?.email}
                          name="email"
                          disabled
                          type="text"
                        />
                        <label>Business Email Address</label>
                      </div>
                    </div>
                  )}
                </>
              )}

              {!sendAccessLink && (
                <button
                  id="ALBtn"
                  disabled={isSubmitting}
                  onClick={handleSendAccess}
                  className="as_info_btn  btn-uppercase btn-block mb-4 w-100"
                  title={
                    sendAccessCode
                      ? "Continue with access code"
                      : "Send me an access link"
                  }
                >
                  {isSubmitting ? (
                    <Spinner />
                  ) : sendAccessCode ? (
                    "Continue with access code"
                  ) : (
                    "Send me an access link"
                  )}
                </button>
              )}

              <p>
                If you are having problems with the access{" "}
                {sendAccessCode ? " code" : " link"}, you can be sent an{" "}
                <Link
                  to="#"
                  id={sendAccessCode ? "access-code" : " access-link"}
                  role="button"
                  className={isSubmitting ? "link-disabled" : "link"}
                  onClick={() => {
                    sendAccessCode
                      ? handleSendAccessCodeLink("resend-email")
                      : handleSendAccessCodeLink("resend-otp");
                  }}
                >
                  access {!sendAccessCode ? " code" : " link"}
                </Link>{" "}
                instead.
              </p>

              <hr></hr>

              <p className="font-s-13 mb-2">
                Support Email:{" "}
                <Link
                  to={"mailto:" + process.env.REACT_APP_SUPPORT_EMAIL}
                  title="Email"
                >
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </Link>
              </p>
              <p className="font-s-13">
                Support Phone:{" "}
                <Link
                  to={"tel:" + process.env.REACT_APP_SUPPORT_PHONE}
                  title="Contact number"
                >
                  {process.env.REACT_APP_SUPPORT_PHONE}
                </Link>
              </p>
            </div>
          </div>
          <div className="col-lg-5 px-lg-0 rounded-end bg_light">
            <div className="d-lg-block d-none">
              <div className="p-5 ">
                <div className="text-center mt-6">
                  <img
                    alt="NeoImpact"
                    width="144"
                    height="36"
                    loading="lazy"
                    src={logo}
                  />
                </div>
                <div className="">
                  <p className="mt-4">
                    Benefits of NeoImpact Databook account:{" "}
                  </p>
                  <ul className="">
                    <li className="mt-4">
                      <BsCheckCircle size={16} className="me-2" /> Access to ESG
                      fundamental datasets
                    </li>
                    <li className="mt-3">
                      <BsCheckCircle size={16} className="me-2" /> Monitor ESG
                      regulatory landscape
                    </li>
                    <li className="mt-3">
                      <BsCheckCircle size={16} className="me-2" /> Assess
                      industry innovation quotient
                    </li>
                    <li className="mt-3">
                      <BsCheckCircle size={16} className="me-2" /> Track
                      reputational risk
                    </li>
                    <li className="mt-3">
                      <BsCheckCircle size={16} className="me-2" /> Leverage ESG
                      advisory solutions
                    </li>
                    <li className="mt-3">
                      <BsCheckCircle size={16} className="me-2" /> Enable
                      informed decision making
                    </li>
                  </ul>
                  <div className="d-block mt-6">
                    <p>
                      Already have an account?
                      <Link
                        to="#"
                        onClick={() => navigate("/sign-in#")}
                        className="text-decoration-underline  fw-semibold"
                      >
                        {" Sign in "}
                      </Link>
                      here.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SignUpOtpModal;
