import axios from "axios";
import config from "../config";
// import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";

const useAuthenticatedAxios = () => {
  // const navigate = useNavigate();
  const { logout } = useAuth();
  const instance = axios.create({
    baseURL: config.apiBase,
    headers: {
      accept: "application/json",
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token") || "";
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        localStorage.removeItem("token"); // Ensure the token is cleared
        logout();
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      // Handle 401 Unauthorized error
      if (err.response) {
        if (
          err?.response?.status === 401 ||
          err?.response?.message === "Unauthorized"
        ) {
          // If the API returns a 401 Unauthorized error, log the user out
          localStorage.removeItem("token"); // Clear the token
          logout();
        }
        if (err.response.status === 403 && err.response.data) {
          return Promise.reject(err.response.data);
        }
      }

      return Promise.reject(err);
    }
  );
  return instance;
};

const useAxios = () => {
  const instance = axios.create({
    baseURL: config.apiBase,
    headers: {
      accept: "application/json",
    },
  });

  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export { useAxios, useAuthenticatedAxios };
