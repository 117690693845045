import { useEffect, useState } from "react";
import { GlobalSearchIcon } from "../../icons";

export const GlobalSearch = () => {
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const [query, setQuery] = useState(params.get("search"));
  useEffect(() => {
    setQuery(params.get("search"));
    //eslint-disable-next-line
  }, [params.get("search")]);

  return (
    <form action="/search">
      <div className="search-dropdown">
        <div className="control">
          <div className="selected-value">
            <input
              aria-autocomplete="list"
              aria-labelledby="downshift-12-label"
              autoComplete="off"
              id="downshift-12-input"
              data-transaction-name="search input"
              placeholder="Find ESG Disclosure, Regulations, Patents, Investments and many more..."
              className="form-control border text-black font-s-14 sui-search-box__text-input header-s-input ml-5"
              type="text"
              value={query || ""}
              name="search"
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <div className="icn_search " style={{ left: "15px", top: "15px" }}>
              <GlobalSearchIcon />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
