import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

interface Column {
  key: string;
  label: string;
  render?: (row: any) => React.ReactNode; // Optional custom render for cells to print custom values
  width?: string;
  sortable?: boolean; // New property to mark columns as sortable
}

interface TableProps {
  data: any[];
  columns: Column[];
  loading?: boolean;
  isBorderdTable?: boolean;
  emptyMessage?: {
    title: string;
  };
  sortColumn?: (column: string) => void;
  onRowClick?: (row: any) => void;
}

const DataTable: React.FC<TableProps> = ({
  data,
  columns,
  loading = false,
  isBorderdTable = true,
  emptyMessage = { title: "No Data", description: "No results found." },
  sortColumn,
  onRowClick,
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    order: "asc" | "desc";
  } | null>(null);
  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  useEffect(() => {
    if (data?.length > 0) {
      setSelectedRow(data[0]);
      onRowClick?.(data[0]);
    }
    //eslint-disable-next-line
  }, [data]);

  const handleRowClick = (row: any) => {
    setSelectedRow(row);
    onRowClick?.(row);
  };

  // Sort data based on the current sortConfig
  const sortedData = React.useMemo(() => {
    if (!sortConfig || !data.length) return data;

    const sorted = [...data].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) return sortConfig.order === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.order === "asc" ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [data, sortConfig]);

  const handleSort = (key: string) => {
    setSortConfig((prevConfig) => {
      if (prevConfig?.key === key) {
        // Toggle sort order
        return { key, order: prevConfig.order === "asc" ? "desc" : "asc" };
      }
      return { key, order: "asc" }; // Default to ascending order
    });
    sortColumn && sortColumn(key);
  };

  return (
    <div className="table-responsive mt-0">
      <Table
        className={isBorderdTable ? "table-bordered mb-0 table" : " table"}
      >
        <thead>
          <tr>
            {columns?.map((col) => (
              <th
                key={col?.key}
                style={{
                  width: col?.width || "auto",
                  cursor: col.sortable ? "pointer" : "default",
                }}
                onClick={() => col.sortable && handleSort(col.key)}
              >
                {col?.label}
                {col.sortable && (
                  <span>
                    {sortConfig?.key === col.key
                      ? sortConfig.order === "asc"
                        ? " ▲"
                        : " ▼"
                      : " ⬍"}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading && data?.length > 0 ? (
            data?.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={() => handleRowClick(row)}
                style={{
                  backgroundColor:
                    row === selectedRow ? "#f0f8ff" : "transparent",
                  cursor: "pointer",
                }}
              >
                {columns?.map((col) => (
                  <td
                    key={col.key}
                    style={{
                      width: col.width || "auto",
                      backgroundColor:
                        row === selectedRow ? "#f0f8ff" : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    {col.render ? col.render(row) : row[col.key] || "--"}
                  </td>
                ))}
              </tr>
            ))
          ) : !loading && sortedData?.length === 0 ? (
            <tr>
              <td colSpan={columns.length}>
                <div className="text-center p-3">
                  <h6>{emptyMessage.title}</h6>
                </div>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={columns?.length + 1}>
                <div className="">
                  {[...Array(data?.length || 5)]?.map((_, index) => (
                    <div key={index} className="mb-2">
                      <Skeleton height={30} width={"100%"} />
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DataTable;
