import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="hk-footer">
        <footer className="container-xxl footer">
          <div className="row">
            <div className="col-12">
              <p className="footer-text text-center">
                <span className="copy-text">Copyright @ NeoImpact</span>
                <Link to="">Contact</Link>
                <span className="footer-link-sep">|</span>
                <Link to="#">About</Link>
                <span className="footer-link-sep">|</span>
                <Link
                  title="Privacy policy"
                  to="https://www.grandviewresearch.com/info/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                <span className="footer-link-sep">|</span>
                <Link
                  title="Terms & Conditions"
                  to="https://www.grandviewresearch.com/info/terms-of-use"
                >
                  Terms &amp; Conditions
                </Link>
              </p>
            </div>
            <div className="col-xl-4" />
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
