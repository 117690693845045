import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ChangePassword from "./ChangePassword";
import { toast } from "react-toastify";
import config from "../../config";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import CountryCodeInput from "../../components/common/Inputs/CountryCodeInput";

const Profile = () => {
  const { user, updateUser } = useAuth();
  const axios = useAuthenticatedAxios();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("activeTab");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userDetail, setUserDetail] = useState({
    id: user?._id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    mobile_number: user?.mobile_number,
    country_code: user?.country_code,
    description: "",
  });
  useEffect(() => {
    setUserDetail({
      id: user?._id,
      firstName: user?.firstName || "",
      lastName: user?.lastName === "undefined" ? "" : user?.lastName || "",
      mobile_number: user?.mobile_number,
      country_code:
        user?.country_code === "undefined" ? "" : user?.country_code || "+91",
      description: "",
    });
  }, [user]);

  const handleProfileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setUserDetail((oldDetail) => ({
      ...oldDetail,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleTabClick = (tab: string) => {
    setSearchParams({ activeTab: tab });
  };
  const onChangeNumber = (value: string) => {
    setUserDetail((oldDetail) => {
      return { ...oldDetail, mobile_number: value };
    });
  };
  const onChangeCountryCode = (value: string) => {
    setUserDetail((oldDetail) => {
      return { ...oldDetail, country_code: value };
    });
  };
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var phonenoRegx = /^\d{10}$/;

    if (userDetail?.firstName === "") {
      toast.error("Please enter your first name");
      return;
    }
    if (userDetail?.lastName === "") {
      toast.error("Please enter your last name");
      return;
    }

    if (userDetail?.mobile_number === "") {
      toast.error("Please enter your phone number");
      return;
    } else if (
      userDetail?.mobile_number?.length !== 10 ||
      !userDetail?.mobile_number?.match(phonenoRegx)
    ) {
      toast.error("Invalid phone number");
      return;
    }
    if (userDetail?.country_code === "") {
      toast.error("Please enter your country code?");
      return;
    }
    setIsSubmitting(true);
    try {
      try {
        const response = await axios.put(
          config.apiEndpoints.updateUser,
          userDetail
        );
        if (response?.data && response?.data?.status === 200) {
          const updatedUser = { ...user, ...response?.data?.data }; // Merge the updated data with the current user
          updateUser(updatedUser);
          toast.success(response?.data?.message);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmitting(false);
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="card ">
      <div className="profile-wrap m-3">
        <div className="profile-intro mt-0">
          <div className="card card-flush mw-400p bg-transparent">
            <div className="row">
              <h4>
                {user?.firstName} {user?.lastName}
                <i
                  className="bi-check-circle-fill fs-6 text-blue"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  data-bs-original-title="Top endorsed"
                ></i>
              </h4>
              <p>{user ? user.email : ""}</p>
            </div>
          </div>
        </div>
        <header className="profile-header w-25">
          <ul className="nav nav-tabs nav-icon nav-light h-100 d-flex justify-content-between">
            <li className="nav-item">
              <Link
                className={`d-flex align-items-center nav-link h-100 ${
                  activeTab === "manage-profile" ? "active" : ""
                } `}
                data-bs-toggle="tab"
                to={"profile?activeTab=manage-profile"}
                onClick={() => handleTabClick("manage-profile")}
                title="Profile"
              >
                <span className="nav-link-text">Profile</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`d-flex align-items-center nav-link h-100 ${
                  activeTab === "change-password" ? "active" : ""
                }`}
                data-bs-toggle="tab"
                to={"profile?activeTab=change-password"}
                onClick={() => handleTabClick("change-password")}
                title="Password"
              >
                <span className="nav-link-text">Password</span>
              </Link>
            </li>
          </ul>
        </header>
        <div className="row mt-4">
          <div
            className={
              activeTab === "contact-us" ||
              activeTab === "invite-colleagues" ||
              activeTab === "subscription"
                ? "col-lg-12"
                : "col-lg-12 mb-lg-0 mb-3"
            }
          >
            <div className="tab-content mt-0" id="myTabContent">
              <div
                className={`tab-pane fade ${
                  activeTab === null || activeTab === "manage-profile"
                    ? "show active"
                    : ""
                }`}
                id="profile_tab"
                role="tabpanel"
                aria-labelledby="profile_tab-tab"
              >
                <div className="col-lg-12">
                  <div className="">
                    <div className="">
                      <div className="row">
                        <form onSubmit={handleSubmit}>
                          {/* <div className="gx-3 row"><div className="col-sm-12"><div><div className="media align-items-center"><div className="media-head me-5"><div className="avatar avatar-rounded avatar-xxl"><img src="/assets/avatar3-CedfJ5CS.jpg" alt="user" className="avatar-img" /></div></div><div className="media-body"><button type="button" className="btn-file mb-1 btn btn-soft-primary" fdprocessedid="pzwq67">Upload Photo<input type="file" className="upload form-control" /></button><div className="form-text text-muted form-text">For better preview recommended size is 450px x 450px. Max size 5mb.</div></div></div></div></div></div> */}
                          <div className="row gx-3">
                            <div className="col-lg-4 col-sm-6">
                              <div className="title title-xs title-wth-divider text-primary text-uppercase my-4">
                                <span>Personal Info</span>
                              </div>

                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label className="text_purple2 font-s-12 fw-semibold mb-1">
                                      First Name*
                                    </label>
                                    <input
                                      id="firstName"
                                      type="text"
                                      className="form-control bg-transparent"
                                      name="firstName"
                                      value={userDetail?.firstName}
                                      required
                                      onChange={handleProfileChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label className="text_purple2 font-s-12 fw-semibold mb-1">
                                      Last Name*
                                    </label>
                                    <input
                                      id="lastName"
                                      type="text"
                                      className="form-control bg-transparent"
                                      name="lastName"
                                      value={userDetail?.lastName}
                                      required
                                      onChange={handleProfileChange}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label className="text_purple2 font-s-12 fw-semibold mb-1">
                                      Phone Number{" "}
                                    </label>
                                    <CountryCodeInput
                                      onChangeNumber={onChangeNumber}
                                      defaultValue={userDetail?.mobile_number}
                                      defaultCountryCode={
                                        userDetail?.country_code
                                          ? userDetail?.country_code
                                          : "+91"
                                      }
                                      onChangeCountryCode={onChangeCountryCode}
                                      inputClassName="h-50p bg-transparent"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row gx-3">
                            <div className="col-lg-12">
                              <button
                                disabled={isSubmitting}
                                type="submit"
                                className="btn btn-primary-horizon"
                                title="Save Changes"
                              >
                                {isSubmitting ? <Spinner /> : "Save Changes"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {activeTab === "change-password" && (
                <ChangePassword activeTab={activeTab} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
