import { Navigate, useOutlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Header from "../components/Header";
import { HelmetProvider } from "react-helmet-async";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <HelmetProvider>
      <>
        <div
          className="hk-wrapper hz_layout_2"
          data-layout="vertical"
          data-layout-style="default"
          data-menu="light"
          data-footer="simple"
        >
          <Header />
          <div>
            <Sidebar />
            <div id="hk_menu_backdrop" className="hk-menu-backdrop"></div>
            <div className="hk-pg-wrapper pb-10">
              <div className="container-fluid px-4">
                <div className="hk-pg-body pt-2"> {outlet}</div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        {showScroll && (
          <button
            onClick={scrollToTop}
            style={{
              position: "fixed",
              bottom: "30px",
              right: "30px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              zIndex: 1000,
            }}
          >
           <FaArrowCircleUp/>
          </button>
        )}
      </>
    </HelmetProvider>
  );
};
