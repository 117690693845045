import React, { useState, ChangeEvent } from "react";
import "./CountryCodeInput.css";

interface CountryCodeInputProps {
  onChangeNumber: (number: string) => void;
  onChangeCountryCode: (code: string) => void;
  defaultValue?: string;
  defaultCountryCode?: string;
  disabled?: boolean;
  inputClassName?: string;
  ccFieldClassName?: string;
}

const CountryCodeInput: React.FC<CountryCodeInputProps> = ({
  onChangeNumber,
  onChangeCountryCode,
  defaultValue = "",
  defaultCountryCode = "",
  disabled = false,
  inputClassName = "",
  ccFieldClassName = "",
}) => {
  const [countryCode, setCountryCode] = useState<string>(
    defaultCountryCode ? defaultCountryCode.toString() : ""
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    defaultValue ? defaultValue.toString() : ""
  );
  const [validationMessage, setValidationMessage] = useState<string>("");

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChangeNumber(value);
    setPhoneNumber(value);
    setValidationMessage("");
  };

  const handleCountryCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCountryCode(value);
    onChangeCountryCode(value);
    setValidationMessage("");
  };

  return (
    <>
      <div className="form_row country_select_full">
        <div className="country_select">
          <input
            id="phoneSelector-req"
            name="data[Lead][phone_ext]"
            type="text"
            value={countryCode}
            onChange={handleCountryCodeChange}
            placeholder="+91"
            disabled={disabled}
            className={`form_input form-control  ${
              disabled ? "phone-disable" : ""
            }  ${
              inputClassName ? inputClassName : "bg-transparent"
            } ${ccFieldClassName} w-100`}
          />
        </div>
        <div className="input text required">
          <input
            name="data[Lead][mobile_number]"
            id="LeadPhoneNo"
            type="text"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Phone Number*"
            disabled={disabled}
            className={`form_input form-control  ${
              disabled ? "phone-disable" : ""
            }  ${inputClassName ? inputClassName : "bg-transparent"} w-100`}
          />
        </div>
      </div>
      {validationMessage && (
        <span className="text-danger fs-8">{validationMessage}</span>
      )}
    </>
  );
};

export default CountryCodeInput;
