import {
  FilterableFieldsGroupInterface,
  SortableFieldsInterface,
} from "../hooks/useFilter";

export const filterableFields: Array<FilterableFieldsGroupInterface> = [
  {
    title: "Company",
    fields: [
      {
        label: "Name",
        value: "name",
        type: "string",
      },
      {
        label: "Revenue",
        value: "revenue_range",
        type: "string",
      },
      {
        label: "Employees",
        value: "num_employees_enum",
        type: "number",
      },
      {
        label: "Created On",
        value: "num_employees_enum",
        type: "datetime",
      },
    ],
  },
  {
    title: "Location",
    fields: [
      {
        label: "City",
        value: "city",
        type: "string",
      },
      {
        label: "Country",
        value: "country",
        type: "string",
      },
    ],
  },
  {
    title: "Status",
    fields: [
      {
        label: "Operating Status",
        value: "operating_status",
        type: "string",
      },
      {
        label: "IPO Status",
        value: "ipo_status",
        type: "string",
      },
    ],
  },
];

export const sortableFields: Array<SortableFieldsInterface> = [
  { label: "Name", value: "name" },
  { label: "Revenue", value: "revenue_range" },
  { label: "Employees", value: "num_employees_enum" },
];

export const companiesfilterableFields: Array<FilterableFieldsGroupInterface> =
  [
    {
      title: "Company",
      fields: [
        {
          label: "Name",
          value: "name",
          type: "string",
        },
        {
          label: "Revenue",
          value: "revenue_range",
          type: "string",
        },
        {
          label: "Employees",
          value: "num_employees_enum",
          type: "number",
        },
      ],
    },
    {
      title: "Head Quarter",
      fields: [
        {
          label: "Country",
          value: "head_quarter.name",
          type: "string",
        },
      ],
    },
    {
      title: "Head Quarter",
      fields: [
        {
          label: "Published on",
          value: "head_quarter.name",
          type: "datetime",
        },
      ],
    },
  ];

export const patentfilterableFields: Array<FilterableFieldsGroupInterface> = [
  {
    title: "Patent",
    fields: [
      {
        label: "Title",
        value: "patent_title",
        type: "string",
      },

      {
        label: "Publication Identifier",
        value: "patent_id",
        type: "string",
      },
      {
        label: "Patent Date",
        value: "patent_date",
        type: "datetime",
      },
      {
        label: "Patent Type",
        value: "patent_type",
        type: "string",
      },
    ],
  },
  {
    title: "Company",
    fields: [
      {
        label: "Name",
        value: "company.name",
        type: "string",
      },
    ],
  },
  {
    title: "Taxonomy",
    fields: [
      {
        label: "Pillar",
        value: "taxonomy.pillar",
        type: "string",
      },
    ],
  },
  {
    title: "Location",
    fields: [
      {
        label: "Country",
        value: "app_country",
        type: "string",
      },
    ],
  },
];

export const jobfilterableFields: Array<FilterableFieldsGroupInterface> = [
  {
    title: "Job",
    fields: [
      {
        label: "Title",
        value: "job_title",
        type: "string",
      },

      {
        label: "Posted On",
        value: "job_posted_at_datetime_utc",
        type: "datetime",
      },
    ],
  },
  {
    title: "Company",
    fields: [
      {
        label: "Name",
        value: "company.name",
        type: "string",
      },
    ],
  },
  {
    title: "Location",
    fields: [
      {
        label: "Country",
        value: "job_country",
        type: "string",
      },
      {
        label: "State",
        value: "job_state",
        type: "string",
      },
      {
        label: "City",
        value: "job_city",
        type: "string",
      },
    ],
  },
];

export const regulationfilterableFields: Array<FilterableFieldsGroupInterface> =
  [
    {
      title: "Regulation",
      fields: [
        {
          label: "Title",
          value: "title",
          type: "string",
        },

        {
          label: "Regulation No",
          value: "regulation_id",
          type: "string",
        },
        {
          label: "Theme",
          value: "theme",
          type: "string",
        },
      ],
    },
    {
      title: "Location",
      fields: [
        {
          label: "Country",
          value: "country.name",
          type: "string",
        },
      ],
    },
  ];

export const newsfilterableFields: Array<FilterableFieldsGroupInterface> = [
  {
    title: "News",
    fields: [
      {
        label: "Title",
        value: "title",
        type: "string",
      },
      {
        label: "Dimension",
        value: "dimension",
        type: "string",
      },

      {
        label: "Publish Date",
        value: "publish_date_ftd",
        type: "datetime",
      },
    ],
  },
  {
    title: "Company",
    fields: [
      {
        label: "Name",
        value: "company.name",
        type: "string",
      },
    ],
  },
];
