import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import DataTable from "../../components/common/DataTable";
import Skeleton from "react-loading-skeleton";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";

const CompanyLocations = () => {
  const { id = "" } = useParams();
  const [activePage, setActivePage] = useState(1);
  const [selectedTag, setSelectedTag] = useState("");
  const axios = useAuthenticatedAxios();
  const navigate = useNavigate();
  const [regulationsData, setRegulationsData] = useState<any>([]);
  const [companyDetails, setCompanyDetails] = useState<any>({});
  const [locations, setLocations] = useState<any>([]);
  const [countryId, setCountryId] = useState("");
  const [loading, setLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      setCompanyLoading(true);
      try {
        const response = await axios.get(
          `${config.apiEndpoints.getCompanyDetail}/${id}`
        );
        setCompanyDetails(response?.data?.data);
        let location = response?.data?.data?.locations;
        setLocations(location || []);
        if (countryId === "" && location?.length > 0) {
          setCountryId(location[0]?.id);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      } finally {
        setCompanyLoading(false);
      }
    };

    fetchCompanyDetails();
    //eslint-disable-next-line
  }, [id]);

  const fetchRegulations = async () => {
    setLoading(true);
    try {
      let url = `${config.apiEndpoints.getFilteredRegulations}?page=${activePage}`;
      if (countryId) {
        url += `&country_id=${countryId}`;
      }
      if (selectedTag) {
        url += `&taxonomy=${selectedTag}`;
      }
      const response = await axios.get(url);
      setRegulationsData(response?.data || []);
    } catch (error: any) {
      // toast.error("Error fetching regulations");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTag || countryId) {
      fetchRegulations();
    }
    //eslint-disable-next-line
  }, [selectedTag, countryId, activePage]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };
  const handleTagClick = (tag: string) => {
    setSelectedTag(tag);
  };

  const columns = [
    {
      name: "S.N.",
      selector: (row: any) => row.regulation_id,
      key: "regulation_id",
      label: "S.N.",
      width: "10%",
    },
    {
      key: "title",
      label: "Regulation Title",
      render: (row: any) => (
        <Link to={row.link} target="_blank" rel="noopener noreferrer">
          <span>{row?.title}</span>
        </Link>
      ),
      width: "20%",
    },
    {
      key: "description",
      label: "Regulation Summary",
      render: (reg: any) => (
        <div>
          <span>{reg?.description}</span>
          {reg?.taxonomy?.map((taxo: any, index: any) => (
            <span key={index} className="badge badge-soft-primary ms-2">
              {taxo}
            </span>
          ))}
        </div>
      ),
      sortable: false,
      width: "40%",
    },
    {
      selector: (row: any) => row.issuer_authority,
      sortable: true,
      key: "issuer_authority",
      label: "Issuer authority",
      width: "20%",
    },
  ];

  return (
    <>
      <div className="container-fluid px-4">
        <div className="hk-pg-body pt-2 pb-14">
          <section className="my_portfolio">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h1 className="text-black fw-semibold h4">
                    {companyDetails?.name || ""}
                  </h1>
                  <button
                    onClick={() => navigate("/portfolio")}
                    className="btn btn-sm btn-outline-primary mb-1"
                    style={{ cursor: "pointer" }}
                  >
                    Back
                  </button>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-5 col-12">
                        <div
                          className="nav flex-column nav-pills border bg-white rounded"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <div className="nav-link">
                            <h5 className="text_color_3 fw-semibold mb-0">
                              Geographical Presence
                            </h5>
                          </div>

                          {companyLoading ? (
                            [...Array(locations?.length || 5)].map(
                              (_, index) => (
                                <div key={index}>
                                  <Skeleton height={40} width={"100%"} />
                                </div>
                              )
                            )
                          ) : locations.length > 0 ? (
                            locations.map((location: any, index: number) => (
                              <button
                                key={index}
                                className={`nav-link ${
                                  location?.id === countryId ? "active" : ""
                                }`}
                                onClick={() => {
                                  setCountryId(location?.id);
                                  setActivePage(1);
                                }}
                              >
                                <div className="compeny_logo">
                                  {location?.name?.charAt(0)?.toUpperCase()}
                                </div>
                                {location?.name}
                              </button>
                            ))
                          ) : (
                            <div className="text-center py-3">
                              No Locations Found
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-7 col-12">
                        <div className="row mb-4">
                          <div className="col-12">
                            <div className="companies_filter border bg-white rounded p-4">
                              <div className="col-12">
                                <h5 className="text-black fw-semibold mb-3">
                                  Filter by tags
                                </h5>
                              </div>
                              <div className="row">
                                <ul className="d-flex flex-wrap justify-content-between grid gap-3">
                                  {[
                                    "Environment",
                                    "Social",
                                    "Common",
                                    "Governance",
                                  ].map((tag, index) => (
                                    <li key={index}>
                                      <Link
                                        to="#"
                                        className="tag_1"
                                        onClick={() => {
                                          handleTagClick(tag);
                                          setActivePage(1);
                                        }}
                                      >
                                        {tag}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-content mt-md-0"
                          id="v-pills-tabContent"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-oman"
                            role="tabpanel"
                            aria-labelledby="location-tab-1"
                            tabIndex={0}
                          >
                            <div className="card card-border mb-4 table_layout2">
                              <div className="position-relative">
                                <DataTable
                                  loading={loading || companyLoading}
                                  data={regulationsData?.data || []}
                                  columns={columns}
                                  emptyMessage={{
                                    title: "No Regulations Found",
                                  }}
                                />
                              </div>
                            </div>
                            {regulationsData?.data?.length > 0 && (
                              <Pagination
                                activePage={activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={regulationsData?.total || 0}
                                pageRangeDisplayed={10}
                                itemClass="page-item"
                                linkClass="page-link"
                                onChange={handlePageChange}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default CompanyLocations;
