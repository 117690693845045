import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import AuthRightContent from "../../layouts/AuthRightContent";
import { ChangeEvent, FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { useAxios } from "../../hooks/useAxios";
import { Spinner } from "react-bootstrap";
import config from "../../config";

interface ForgotPasswordData {
  email: string;
}
const ForgotPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaultValues: ForgotPasswordData = { email: "" };
  const [detail, setDetails] = useState<ForgotPasswordData>(defaultValues);
  const axios = useAxios();
  const validateEmail = (email: string) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  };
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDetails((oldDetail) => ({
      ...oldDetail,
      [name]: value,
    }));
  };
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!detail.email) {
      toast.error("What's your email?");
      return;
    }

    if (!validateEmail(detail.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        config.apiEndpoints.forgotPassword,
        detail
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <div className="row align-items-center sign-up1-sec">
        <div className="col-xl-4 col-lg-5 col-md-6 position-relative px-0">
          <div
            className="bg-white align-content-center h-735p"
            style={{ minHeight: 735 }}
          >
            <div className="pt-0 px-lg-7 px-5 pb-5">
              <div className="text-center mb-6">
                <a className="navbar-brand" title="NeoImpact" href="/">
                  <img
                    className="brand-img d-inline-block"
                    src={logo}
                    width={144}
                    height={36}
                    alt="NeoImpact"
                    loading="lazy"
                  />
                </a>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-label-group mb-4">
                  <h4 className="my-0">Forgot Password?</h4>
                  <Link
                    to="/login"
                    title="Sign In"
                    className="text-decoration-underline"
                  >
                    <u>Back to Sign in</u>
                  </Link>
                </div>
                <div className="row gx-3" />
                <div className="form-floating mb-4">
                  <input
                    className="form-control"
                    placeholder="Enter your email address"
                    type="email"
                    id="floatingEmail"
                    value={detail.email}
                    name="email"
                    onChange={handleOnChange}
                  />
                  <label htmlFor="floatingEmail">Email Address</label>
                </div>
                <div className="mb-4">
                  <button
                    className="as_info_btn btn-uppercase btn-block w-100"
                    title="Send Reset Password Link"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <Spinner
                        style={{
                          height: "15px",
                          width: "15px",
                          marginTop: "2px",
                          marginRight: "2px",
                        }}
                      />
                    )}{" "}
                    Send Reset Password Link
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <AuthRightContent />
      </div>
    </>
  );
};
export default ForgotPassword;
