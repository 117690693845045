import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import useAuth from "../../hooks/useAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import config from "../../config";
import { useAxios } from "../../hooks/useAxios";
import AuthRightContent from "../../layouts/AuthRightContent";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = () => {
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const axios = useAxios();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      setErrorMessage("");
      try {
        const response = await axios.post(config.apiEndpoints.login, values);
        let user = response?.data?.user;
        if (user) {
          toast.success("Logged in successfully");
          login(user);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <div className="row align-items-center sign-up1-sec">
      <div className="col-xl-4 col-lg-5 col-md-6 position-relative px-0">
        <div
          className="bg-white align-content-center h-735p"
          style={{ minHeight: 735 }}
        >
          <div className="pt-0 px-lg-7 px-5 pb-5">
            <div className="text-center mb-6">
              <a className="navbar-brand" title="NeoImpact" href="/">
                <img
                  className="brand-img d-inline-block"
                  src={logo}
                  width={144}
                  height={36}
                  alt="NeoImpact"
                  loading="lazy"
                />
              </a>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-label-group mb-4">
                <h4 className="my-0">Sign in</h4>
                <Link to="/sign-up" className="text-decoration-underline">
                  Create free account
                </Link>
              </div>
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              <div className="form-floating mb-4">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  disabled={isSubmitting}
                />
                <label htmlFor="email">Email address</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  disabled={isSubmitting}
                />
                <Link
                  to="#"
                  onClick={handleTogglePassword}
                  className="input-suffix text-muted"
                  title={showPassword ? "Hide Password" : "View Password"}
                  style={{
                    position: "absolute",
                    top: "16px",
                    right: "5px",
                  }}
                >
                  {formik.values.password !== "" &&
                    (showPassword ? <FaEyeSlash /> : <FaEye />)}
                </Link>
                <label htmlFor="password">Password</label>
              </div>
              <div className="form-check form-check-sm mb-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="remember"
                  id="remember"
                />
                <label
                  className="form-check-label text-muted fs-7"
                  htmlFor="remember"
                >
                  Keep me logged in
                </label>
                <a className="float-end font-s-12" href="/forgot-password">
                  Forgot Password?
                </a>
              </div>
              <div className="mb-4">
                <button
                  className="as_info_btn btn-uppercase btn-block w-100"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Logging in..." : "Log In"}
                </button>
              </div>
              <p className="font-s-13 mb-2 text_purple2">
                Support Email :{" "}
                <Link
                  className="font-s-11"
                  to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                >
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </Link>
              </p>
              <p className="font-s-13  text_purple2">
                Support Phone:{" "}
                <Link to={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`}>
                  {process.env.REACT_APP_SUPPORT_PHONE}
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
      <AuthRightContent />
    </div>
  );
};

export default Login;
