import React, { useState, useEffect, useRef, forwardRef } from "react";
import Select from "react-dropdown-select";
import { useAuthenticatedAxios } from "../../hooks/useAxios";

export const DropdownComponent = forwardRef(
  (
    {
      searchBy,
      valueField,
      labelField,
      searchUrl,
      label,
      options,
      inputChange,
      handleChange,
      value,
      searchable,
      isMulti,
      isloading,
      name,
    }: any,
    ref
  ) => {
    const axios = useAuthenticatedAxios();
    const [searchOptions, setSearchOptions] = useState<any[]>(options || []);
    const timerRef = useRef<number | undefined>(undefined); // Timer reference for debounce
    const [loading, setLoading] = useState(false);
    // Handle search input change with debouncing
    const handleInputChange = async ({ value }: any) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current); // Clear the previous timer
      }

      timerRef.current = window.setTimeout(async () => {
        setLoading(true);
        try {
          if (!value) {
            setSearchOptions([]); // Clear options if input is empty
            inputChange(""); // Notify parent about the cleared input
            return;
          }
          let apiUrl = searchUrl;
          if (typeof value === "object") {
            apiUrl = searchUrl;
          } else {
            if (apiUrl.includes("?")) {
              apiUrl = `${searchUrl}&q=${value}`;
            } else {
              apiUrl = `${searchUrl}?q=${value}`;
            }
          }

          // Trigger the search API call (adjust based on your API structure)
          const response = await axios.get(apiUrl);

          // Update the search options with the API response
          setSearchOptions(response?.data?.data || []);
          inputChange(value);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }, 300);
    };
    useEffect(() => {
      if (!value) {
        handleChange([]); // Clear selected values
      }
    }, [value, handleChange]);

    useEffect(() => {
      // Cleanup the timer on component unmount
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }, []);

    return (
      <div>
        <Select
          name={name}
          searchBy={searchBy || "name"}
          valueField={valueField || "name"}
          labelField={labelField || "name"}
          options={searchOptions} // Use searchOptions for dynamic options
          values={value ? value : []}
          onChange={(selected) => handleChange(selected || [])}
          handleKeyDownFn={(e) => handleInputChange(e?.event?.target)}
          searchable={searchable || true}
          multi={isMulti || false}
          placeholder={`Select ${label}`}
          className="form-control border text-black font-s-14 rounded header-s-input ml-5"
          loading={isloading || loading}
          onDropdownOpen={() => handleInputChange({ value: value || "" })}
          clearable={true}
        />
      </div>
    );
  }
);
