import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import config from "../../config";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { toast } from "react-toastify";
// import { Spinner } from "react-bootstrap";
import {
  // DeleteRecentVisitedIcon,
  RecentVisitedSideWidget,
} from "../../icons";
import Skeleton from "react-loading-skeleton";

const RecentVisited = () => {
  const axios = useAuthenticatedAxios();
  const [recentVisitedList, setRecentVisitedList] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  // const [isDeleteSubmitting, setIsDeleteSubmitting] = useState<any>({});
  const [selectedType, setSelectedType] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<string>("");
  const length = recentVisitedList?.length || 10;

  const fetchRecentVisited = async (type = "", direction = "") => {
    setLoading(true);
    try {
      const params: any = {};
      if (type) params.type = type;
      if (direction) params.sort_direction = direction;

      const data = await axios.get(config.apiEndpoints.recentVisited, {
        params,
      });
      setRecentVisitedList(data?.data?.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value;
    setSelectedType(type);
    fetchRecentVisited(type, sortDirection);
  };

  const toggleSortDirection = () => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    fetchRecentVisited(selectedType, newDirection);
  };

  useEffect(() => {
    fetchRecentVisited();
    //eslint-disable-next-line
  }, []);

  // const deleteRecentVisited = async (id: string) => {
  //   setIsDeleteSubmitting((prevState: any) => ({ ...prevState, [id]: true }));
  //   try {
  //     const data = await axios.delete(
  //       `${config.apiEndpoints.recentVisited}/${id}`
  //     );
  //     if (data) {
  //       toast.success(data?.data?.message);
  //       fetchRecentVisited();
  //     }
  //     fetchRecentVisited();
  //   } catch (error: any) {
  //     toast.error(error?.response?.data?.message);
  //   } finally {
  //     setIsDeleteSubmitting((prevState: any) => ({
  //       ...prevState,
  //       [id]: false,
  //     }));
  //   }
  // };

  return (
    <>
      <div className="hk-pg-body pt-2">
        <header className="profile-header">
          <ul className="nav nav-line nav-tabs nav-icon nav-light h-100 d-flex">
            <li className="nav-item">
              <Link
                className="d-flex align-items-center nav-link h-100 "
                title="portfolio"
                to="/portfolio"
              >
                <span className="nav-link-text">Portfolio</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="d-flex align-items-center nav-link h-100 active "
                to="/recent-visited"
              >
                <span className="nav-link-text">Recent Visited</span>
              </Link>
            </li>
          </ul>
        </header>
        <div className="row mt-4">
          <label className="font-s-15 fw-semibold form-label">TYPE</label>
          <div className="col-md-3">
            <select
              className="form-select"
              value={selectedType}
              onChange={handleTypeChange}
            >
              <option value="">--</option>
              <option value="Regulation">Regulation</option>
              <option value="Job">Job</option>
              <option value="Company">Company</option>
              <option value="News">News</option>
              <option value="Patent">Patent</option>
              <option value="Industry">Industry</option>
            </select>
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <button
              style={{ padding: "0.1rem 0.5rem" }}
              onClick={toggleSortDirection}
              className="btn btn-outline-secondary"
              title={`Sort: ${sortDirection || "None"}`}
            >
              <label className="font-s-15 fw-semibold form-label mr-5">
                Sort
              </label>
              {sortDirection === "asc" ? (
                <>
                  <BsSortUp size={20} />
                </>
              ) : (
                <>
                  <BsSortDown size={20} />
                </>
              )}
            </button>
          </div>
        </div>

        <div className="row mt-2">
          {loading ? (
            <div>
              {[...Array(length)]?.map((_, index) => (
                <Skeleton key={index} height={80} width={900} />
              ))}
            </div>
          ) : recentVisitedList?.length === 0 ? (
            <div className="row mt-2">
              <span className="pt-3">No data available</span>
            </div>
          ) : (
            <div className="row mt-2">
              {recentVisitedList?.map((item: any) => (
                <div className="col-mb-12 col-xl-8" key={item._id}>
                  <div className="srch_result_col px-mb-2 px-4 py-3">
                    <div className="srch_result_col_top_heading font-s-11 fw-semibold">
                      {item.type}
                    </div>
                    <div className="d-flex align-items-top mb-1">
                      <div className="flex-shrink-0 mt-1">
                        <RecentVisitedSideWidget />
                      </div>
                      <div className="flex-grow-1 ms-2 align-items-center">
                        <Link
                          to={`/${
                            item.type === "Company"
                              ? "company-detail"
                              : "job-detail"
                          }/${item.entity_id}`}
                        >
                          <div className="w-100 pe-2">
                            <span>
                              <h5 className="mb-0 text_purple">
                                {item?.title}
                              </h5>
                            </span>
                          </div>
                        </Link>
                      </div>
                      {/* <div>
                        <button
                          className="btn btn-soft-danger  btn-sm ms-auto remove"
                          onClick={() => deleteRecentVisited(item._id)}
                        >
                          {isDeleteSubmitting[item._id] ? (
                            <Spinner
                              style={{
                                height: "15px",
                                width: "15px",
                                marginTop: "2px",
                                marginRight: "2px",
                              }}
                            />
                          ) : (
                            <DeleteRecentVisitedIcon />
                          )}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RecentVisited;
