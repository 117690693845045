import React, { useEffect, useRef, useState } from "react";
import { useAuthenticatedAxios } from "./useAxios";
// import { get as _get, omit as _omit } from "lodash";
import moment from "moment";
import config from "../config";

export enum FilterStringOperators {
  EQUAL_TO = "eq",
  NOT_EQUAL_TO = "ne",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty",
  CONTAINS = "contains",
  STARTS_WITH = "starts_with",
  ENDS_WITH = "ends_with",
  INCLUDES = "includes",
  INCLUDES_BY_ID = "includes_by_id",
  NOT_INCLUDES = "not_includes",
}

export enum FilterNumberOperators {
  EQUAL_TO = "eq",
  NOT_EQUAL_TO = "ne",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty",
  LESS_THAN = "lt",
  LESS_THAN_EQUAL = "lte",
  GREATER_THAN = "gt",
  GREATER_THAN_EQUAL = "gte",
  BETWEEN = "between",
  NOT_BETWEEN = "not_between",
  INCLUDES = "includes",
  INCLUDES_BY_ID = "includes_by_id",
  NOT_INCLUDES = "not_includes",
  BETWEEN_DATES = "between_date",

}

export enum FilterBooleanOperators {
  TOGGLE = "toggle",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty",
}

export enum FilterDatetimeOperators {
  EQUAL_TO = "eq",
  NOT_EQUAL_TO = "ne",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty",
  LESS_THAN = "lt",
  LESS_THAN_EQUAL = "lte",
  GREATER_THAN = "gt",
  GREATER_THAN_EQUAL = "gte",
  BETWEEN = "between",
  NOT_BETWEEN = "not_between",
}

export type AVAILABLE_OPERATORS = `${
  | FilterBooleanOperators
  | FilterDatetimeOperators
  | FilterNumberOperators
  | FilterStringOperators}`;

const getFilterOperators = (
  fieldType: AVAILABLE_FIELD_TYPES
): Array<AvailableFieldOperatorsInterface> => {
  let operators: Array<AvailableFieldOperatorsInterface> = [];
  switch (fieldType) {
    case "string":
      operators = [
        // {
        //   value: FilterStringOperators.EQUAL_TO,
        //   label: "Equals TO",
        //   columns: 2,
        // },
        // {
        //   value: FilterStringOperators.NOT_EQUAL_TO,
        //   label: "NOT Equals TO",
        //   columns: 1,
        // },
        {
          value: FilterStringOperators.IS_EMPTY,
          label: "Is Empty",
          columns: 0,
        },
        {
          value: FilterStringOperators.IS_NOT_EMPTY,
          label: "Is Not Empty",
          columns: 0,
        },
        {
          value: FilterStringOperators.STARTS_WITH,
          label: "Starts With",
          columns: 1,
        },
        {
          value: FilterStringOperators.ENDS_WITH,
          label: "Ends With",
          columns: 1,
        },
        {
          value: FilterStringOperators.CONTAINS,
          label: "Contains",
          columns: 1,
        },
        {
          value: FilterStringOperators.INCLUDES,
          label: "Includes",
          columns: 1,
        },
        {
          value: FilterStringOperators.INCLUDES_BY_ID,
          label: "includes_by_id",
          columns: 1,
        },
        {
          value: FilterStringOperators.NOT_INCLUDES,
          label: "Not Includes",
          columns: 1,
        },
      ];
      break;
    case "number":
      operators = [
        // {
        //   value: FilterNumberOperators.EQUAL_TO,
        //   label: "Equals TO",
        //   columns: 1,
        // },
        // {
        //   value: FilterNumberOperators.NOT_EQUAL_TO,
        //   label: "NOT Equals TO",
        //   columns: 1,
        // },
        {
          value: FilterNumberOperators.IS_EMPTY,
          label: "Is Empty",
          columns: 0,
        },
        {
          value: FilterNumberOperators.IS_NOT_EMPTY,
          label: "Is Not Empty",
          columns: 0,
        },
        // {
        //   value: FilterNumberOperators.LESS_THAN,
        //   label: "Less Than",
        //   columns: 1,
        // },
        // {
        //   value: FilterNumberOperators.LESS_THAN_EQUAL,
        //   label: "Less Than Equals TO",
        //   columns: 1,
        // },
        // {
        //   value: FilterNumberOperators.GREATER_THAN,
        //   label: "Greater Than",
        //   columns: 1,
        // },
        // {
        //   value: FilterNumberOperators.GREATER_THAN_EQUAL,
        //   label: "Greater Than Equals TO",
        //   columns: 1,
        // },
        {
          value: FilterNumberOperators.BETWEEN,
          label: "In Between",
          columns: 2,
        },
        {
          value: FilterNumberOperators.NOT_BETWEEN,
          label: "Not In Between",
          columns: 2,
        },
        {
          value: FilterNumberOperators.INCLUDES,
          label: "Includes",
          columns: 1,
        },
        {
          value: FilterNumberOperators.INCLUDES_BY_ID,
          label: "includes_by_id",
          columns: 1,
        },
        {
          value: FilterNumberOperators.NOT_INCLUDES,
          label: "Not Includes",
          columns: 1,
        },
      ];
      break;
    case "toggle":
      operators = [
        { value: FilterBooleanOperators.TOGGLE, label: "Toggle", columns: 1 },
        {
          value: FilterBooleanOperators.IS_EMPTY,
          label: "Is Empty",
          columns: 0,
        },
        {
          value: FilterBooleanOperators.IS_NOT_EMPTY,
          label: "Is Not Empty",
          columns: 0,
        },
      ];
      break;
    case "datetime":
      operators = [
        // {
        //   value: FilterDatetimeOperators.EQUAL_TO,
        //   label: "Equals TO",
        //   columns: 1,
        // },
        // {
        //   value: FilterDatetimeOperators.NOT_EQUAL_TO,
        //   label: "NOT Equals TO",
        //   columns: 1,
        // },
        {
          value: FilterDatetimeOperators.IS_EMPTY,
          label: "Is Empty",
          columns: 0,
        },
        {
          value: FilterDatetimeOperators.IS_NOT_EMPTY,
          label: "Is Not Empty",
          columns: 0,
        },
        // {
        //   value: FilterDatetimeOperators.LESS_THAN,
        //   label: "Less Than",
        //   columns: 1,
        // },
        // {
        //   value: FilterDatetimeOperators.LESS_THAN_EQUAL,
        //   label: "Less Than Equals TO",
        //   columns: 1,
        // },
        // {
        //   value: FilterDatetimeOperators.GREATER_THAN,
        //   label: "Greater Than",
        //   columns: 1,
        // },
        // {
        //   value: FilterDatetimeOperators.GREATER_THAN_EQUAL,
        //   label: "Greater Than Equals TO",
        //   columns: 1,
        // },
        {
          value: FilterDatetimeOperators.BETWEEN,
          label: "In Between",
          columns: 2,
        },
        {
          value: FilterDatetimeOperators.NOT_BETWEEN,
          label: "Not In Between",
          columns: 2,
        },
      ];
      break;
    default:
      break;
  }
  return operators;
};

export interface LabelValueInterface {
  label: string;
  value: string;
}

export interface SortableFieldsInterface extends LabelValueInterface {}

export interface AvailableFieldOperatorsInterface extends LabelValueInterface {
  value: AVAILABLE_OPERATORS;
  columns: number;
}

export type AVAILABLE_FIELD_TYPES =
  | "string"
  | "number"
  | "toggle"
  | "datetime"
  | "select"
  | "autocomplete";

export interface FilterableFieldsInterface {
  label: string;
  value: string;
  type: AVAILABLE_FIELD_TYPES;
  placeholder?: string;
  resource?: string;
}

export interface FilterableFieldsGroupInterface {
  title: string;
  fields: Array<FilterableFieldsInterface>;
}

export interface AppliedFiltersInterface {
  title?: string;
  field: string;
  operator: AVAILABLE_OPERATORS | "";
  operators: Array<AvailableFieldOperatorsInterface>;
  query_1: any;
  query_2: any;
  placeholder?: any;
  columns: number;
  columnType: string;
  options?: any;
  searchUrl?: string;
  searchWithKey?: string;
  isMulti?: boolean;
  labelField?: string;
  valueField?: string;
}

export interface UseFilterableProps {
  endpoint: string;
  exportIdentifier?: string;
  exportEndpoint?: string;
  queryParams?: Array<Record<string, any>>;
  filterArray?: any;
  selectedFilters: Array<AppliedFiltersInterface>;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<Array<AppliedFiltersInterface>>
  >;
}

export interface Meta {
  next_token: string | null;
}

export interface UseFilterableResult {
  loading: boolean;
  meta: Record<string, any>;
  records: any;
  exportRecords: () => void;
  fetchRecords: () => void;
  applyFilters: () => void;
  clearFilter: () => void;
  selectedFilters: Array<AppliedFiltersInterface>;
  sortColumn: (column: string) => void;
  removeSortColumn: (column: string) => void;
  resetSorting: () => void;
  addFilter: () => void;
  removeFilter: (value: AppliedFiltersInterface) => void;
  resetFilter: () => void;
  resetNextToken: () => void;
  onFilterColumnSelectHandler: (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onFilterOperatorSelectHandler: (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onFilterValueOneChangeHandler: (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void;
  onFilterValueTwoChangeHandler: (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void;
  updateCursor: (cursor: string) => void;
  error: { type: string; error: string };
}

const useFilter = (props: UseFilterableProps): UseFilterableResult => {
  const {
    endpoint,
    exportEndpoint,
    exportIdentifier,
    queryParams,
    selectedFilters,
    setSelectedFilters,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{
    field?: string;
    type: string;
    error: string;
  }>({
    field: "",
    type: "",
    error: "",
  });
  const [meta, setMeta] = useState<Meta>({ next_token: null });
  const [records, setRecords] = useState<Array<Record<string, any>>>([]);
  const [appliedSorts, setAppliedSorts] = useState<Record<string, any>>({});
  const [refresh, setRefresh] = useState<number>(0);
  const [cursor, setCursor] = useState<string>("");
  const axios = useAuthenticatedAxios();
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const previousQueryRef = useRef<any | null>(null);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  //page based api calling for current scenerio
  const page = queryParams?.find((param) => param.page)?.page || 1;
  useEffect(() => {
    fetchRecords();
    //eslint-disable-next-line
  }, [refresh, page]);

  useEffect(() => {
    if (isFilterChanged) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const timeoutId = setTimeout(() => {
        fetchRecords();
      }, 300);

      setDebounceTimeout(timeoutId);
    } else if (previousQueryRef.current) {
      resetFilter();
    }

    // Update the ref with the current value of companyQuery

    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout); // Clean up timeout on component unmount
    };
    //eslint-disable-next-line
  }, [isFilterChanged, selectedFilters]);
  const fetchRecords = async () => {
    setLoading(true);
    try {
      let params: Record<string, any> = buildQuery();
      const response = await axios.get(endpoint, {
        params: params,
      });
      const response_data = response?.data;
      setRecords(response_data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const exportRecords = async () => {
    if (exportEndpoint) {
      setLoading(true);
      try {
        let params: Record<string, any> = buildQuery();
        const data = {
          export_type: exportIdentifier,
          export_options: {
            filters: params["f"] ? JSON.parse(params["f"]) : [],
            sorting: [],
          },
        };
        await axios.post(exportEndpoint, data);
      } catch (e) {}
      setLoading(false);
    }
  };

  const buildQuery = () => {
    const f: Array<Record<string, any>> = [];

    let sort = {};
    Object.entries(appliedSorts).forEach(([key, value]) => {
      sort = { sort: key, sort_dir: value };
    });
    //eslint-disable-next-line
    let hasError = false;
    selectedFilters?.forEach((filter, i) => {
      if (
        filter.field &&
        filter.field.length &&
        filter.operator &&
        filter.operator.length
      ) {
        const obj: Record<string, any> = {
          column: filter.field,
          operator: filter.operator,
          query_1: null,
          query_2: null,
        };

        // if (!filter?.field) {
        //   setError({ type: "filter", error: "Filter Missing" });
        //   hasError = true;
        //   return; // Exit the current iteration
        // }

        // Check if the 'operator' is missing
        // if (!filter?.operator) {
        //   setError({ type: "operator", error: "Operator Missing" });
        //   hasError = true;
        //   return; // Exit the current iteration
        // }

        // Ensure proper validation for query_1
        // if (
        //   filter?.operator === "ends_with" &&
        //   (!filter?.query_1 || filter.query_1 === null)
        // ) {
        //   setError({ type: "input", error: "Input Value Missing" });
        //   hasError = true;
        //   return; // Exit the current iteration
        // }
        // Ensure proper validation for query_1
        // if (
        //   (filter?.operator === "between" ||
        //     filter?.operator === "not_between") &&
        //   (!filter?.query_1 ||
        //     filter.query_1 === null ||
        //     !filter?.query_2 ||
        //     filter.query_2 === null)
        // ) {
        //   setError({ type: "input", error: "Provide Input Values" });
        //   hasError = true;
        //   return;
        // }

        if (
          filter.query_1 &&
          Array.isArray(filter.query_1) &&
          filter.columnType === "autocomplete"
        ) {
          const listData = filter.query_1
            ?.map((item) => item[filter?.searchWithKey || ""])
            ?.filter(Boolean);
          obj["query_1"] = listData.join(",,");
        } else {
          obj["query_1"] = filter.query_1;
        }
        obj["query_2"] = filter.query_2;

        if (obj.query_1 === null || obj.query_1 === "") {
          return;
        } else {
          f.push(obj);
        }
      }
    });
    const others: Record<string, any> = {};
    if ("next_token" in meta && meta["next_token"]) {
      others["next_token"] = meta["next_token"];
    }
    const pageParam = queryParams?.find((prm) => prm?.page !== undefined);

    if (config?.paginator === "cursor" && cursor !== "") {
      others["page_token"] = cursor;
    } else {
      others["page"] = pageParam?.page;
    }
    const updatedQueryParams = queryParams?.filter((prm) => !prm?.page);

    return {
      ...sort,
      ...updatedQueryParams,
      ...{ f: f?.length ? f : null },
      ...others,
      filter_match: "and",
      paginate: config.paginator,
    };
  };

  const applyFilters = () => {
    resetNextToken();
    setError({ type: "", error: "" }); // Reset errors
    let hasError = false;
    // Validate each selected filter

    selectedFilters.forEach((filter) => {
      if (!filter?.field) {
        setError({ type: "filter", error: "Please select a field." });
        hasError = true;
        return;
      }

      //   if (!filter?.operator) {
      //     setError({ type: "operator", error: "Please select an operator." });
      //     hasError = true;
      //     return;
      //   }

      // if (
      //   ["ends_with", "contains", "starts_with"].includes(filter.operator) &&
      //   (!filter?.query_1 || filter.query_1.trim() === "")
      // ) {
      //   setError({field:filter?.field, type: "input", error: `Please Provide  ${filter?.title} Values` });
      //   hasError = true;
      //   return;
      // }

      if (
        ["between", "not_between"].includes(filter.operator) &&
        filter?.query_1 &&
        filter.query_1.trim() !== "" &&
        (!filter?.query_2 || filter.query_2.trim() === "")
      ) {
        setError({
          field: filter?.field,
          type: "input",
          error: `Please Provide  ${filter?.title} Values`,
        });
        hasError = true;
        return;
      }
    });

    // Refresh only if no errors
    if (!hasError) {
      setRefresh((refresh) => refresh + 1);
    }
  };
  const updateCursor = (cursor: string) => {
    setCursor(cursor);
    setRefresh((refresh) => refresh + 1);
  };

  const clearFilter = () => {
    setSelectedFilters([]); // Clear filters
    resetNextToken();
    setError({ type: "", error: "" });
    setRefresh((refresh) => refresh + 1); // Trigger a refresh to load the unfiltered data
  };

  const sortColumn = (column: string) => {
    setAppliedSorts({
      [column]: appliedSorts[column] === "desc" ? "asc" : "desc",
    });
    setRefresh(refresh + 1);
  };

  const removeSortColumn = (column: string) => {
    if (column in appliedSorts) {
      delete appliedSorts[column];
    }
  };

  const resetSorting = () => {
    setAppliedSorts([]);
  };

  const addFilter = () => {
    setSelectedFilters([
      ...selectedFilters,
      {
        field: "",
        operator: "",
        operators: [],
        query_1: null,
        query_2: null,
        placeholder: null,
        columns: 1,
        columnType: "string",
      },
    ]);
  };

  const removeFilter = (value: AppliedFiltersInterface) => {
    if (selectedFilters.length > 1) {
      setSelectedFilters(selectedFilters.filter((v) => v !== value));
    }
  };

  const resetFilter = () => {
    const clearedFilters = selectedFilters?.map((filter) => ({
      ...filter,
      query_1: null, // reset query_1
      query_2: null, // reset query_2 if necessary
    }));
    setSelectedFilters(clearedFilters);

    // setSelectedFilters(filterArray);
    setError({ type: "input", error: "" });
    resetNextToken();
    setRefresh((refresh) => refresh + 1);
  };

  const onFilterColumnSelectHandler = (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    if (value.length <= 0) {
      return;
    }
    const obj: FilterableFieldsInterface = JSON.parse(value);
    const filterOperators = getFilterOperators(obj.type);
    selectedFilters[i] = {
      field: obj.value,
      operator: filterOperators.length ? filterOperators[0]?.value : "",
      operators: filterOperators,
      query_1: null,
      query_2: null,
      placeholder: obj.placeholder,
      columns: 1,
      columnType: obj.type,
    };
    setSelectedFilters([...selectedFilters]);
  };

  const onFilterOperatorSelectHandler = (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    if (value.length <= 0) {
      return;
    }
    const obj: AvailableFieldOperatorsInterface = JSON.parse(value);
    const sf: AppliedFiltersInterface = selectedFilters[i];
    sf.operator = obj.value;
    sf.columns = obj.columns;
    selectedFilters[i] = sf;
    setSelectedFilters([...selectedFilters]);
  };

  const onFilterValueOneChangeHandler = (
    f: AppliedFiltersInterface,
    i: number,
    e: any
  ) => {
    const value: any = e?.target?.value || "";
    const sf: AppliedFiltersInterface = selectedFilters[i];
    if (f.columnType === "datetime") {
      sf.query_1 = moment(value).toISOString();
    } else if (f.columnType === "autocomplete") {
      const selectedValue = e;
      sf.query_1 = selectedValue;
    } else {
      sf.query_1 = value;
    }
    selectedFilters[i] = sf;
    setSelectedFilters([...selectedFilters]);
    setIsFilterChanged(true);
  };

  const onFilterValueTwoChangeHandler = (
    f: AppliedFiltersInterface,
    i: number,
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const value = e.target.value;
    const sf: AppliedFiltersInterface = selectedFilters[i];
    if (f.columnType === "datetime") {
      sf.query_2 = moment(value).toISOString();
    } else {
      sf.query_2 = value;
    }
    selectedFilters[i] = sf;
    setSelectedFilters([...selectedFilters]);
  };

  const resetNextToken = () => {
    setMeta((state) => ({
      ...meta,
      next_token: null,
    }));
  };

  return {
    loading,
    meta,
    records,
    exportRecords,
    fetchRecords,
    applyFilters,
    clearFilter,
    selectedFilters,
    sortColumn,
    removeSortColumn,
    resetSorting,
    addFilter,
    removeFilter,
    resetFilter,
    resetNextToken,
    onFilterColumnSelectHandler,
    onFilterOperatorSelectHandler,
    onFilterValueOneChangeHandler,
    onFilterValueTwoChangeHandler,
    updateCursor,
    error,
  };
};

export default useFilter;
