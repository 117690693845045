import React, { useState, useEffect } from "react";
import config from "../../config";
import { Link } from "react-router-dom";
import DataTable from "../../components/common/DataTable";
import Pagination from "react-js-pagination";
import Filterable from "../../Filterable";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import { toast } from "react-toastify";
import useFilter, { AppliedFiltersInterface } from "../../hooks/useFilter";
import TabHeader from "../../components/common/TabHeader";
import Skeleton from "react-loading-skeleton";
import { Badge } from "../../components/common/CommonUtils";
import { FaRegStar, FaStar } from "react-icons/fa";
import { LinkIcon } from "../../icons";
import {
  table_view_column,
  details_view_column,
  details_class,
} from "../../components/common/CommonUtils";
import ScoreBlocks from "../../components/common/ProgressSegmentBar";

const Companies = () => {
  const axios = useAuthenticatedAxios();
  const [activePage, setActivePage] = useState(1);
  const [activeTab, setActiveTab] = useState("table");
  const [selectedDescription, setSelectedDescription] = useState<string | null>(
    null
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  // const [selectedSectorId, setSelectedSectorId] = useState("");
  //eslint-disable-next-line
  const [companyList, setCompanyList] = useState<any>([]);
  //eslint-disable-next-line
  const [selectedFilters, setSelectedFilters] = useState<
    Array<AppliedFiltersInterface>
  >([
    {
      title: "Company",
      field: "name",
      operator: "starts_with",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: null,
      columns: 1,
      columnType: "string",
    },
    {
      title: "Sector",
      field: "category.parent.name",
      operator: "includes",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Select sector",
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/get-sector-dd",
      searchWithKey: "name",
      isMulti: true,
    },
    {
      title: "Industry",
      field: "category.name",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Select industry",
      columns: 1,
      columnType: "autocomplete",
      searchUrl: `/get-industry-dd`,
      searchWithKey: "name",
      isMulti: true,
    },
    // {
    //   title: "Sector",
    //   field: "name",
    //   operator: "starts_with",
    //   operators: [],
    //   query_1: null,
    //   query_2: null,
    //   placeholder: "Select sector",
    //   columns: 1,
    //   columnType: "select",
    //   options: [
    //     { value: "industry", label: "Industry" },
    //     { value: "technology", label: "Technology" },
    //     { value: "aerospace", label: "Aerospace" },
    //   ],
    // },
    // {
    //   title: "empolyee ",
    //   field: "employee",
    //   operator: "starts_with",
    //   operators: [],
    //   query_1: null,
    //   query_2: null,
    //   placeholder: null,
    //   columns: 1,
    //   columnType: "number",
    // },
    // {
    //   title: "Published On",
    //   field: "published_on",
    //   operator: "between",
    //   operators: [],
    //   query_1: null,
    //   query_2: null,
    //   placeholder: null,
    //   columns: 1,
    //   columnType: "datetime",
    // },
  ]);

  const {
    loading: useFilterLoading,
    records,
    // selectedFilters,
    sortColumn,
    removeSortColumn,
    resetSorting,
    applyFilters,
    exportRecords,
    resetFilter,
    onFilterColumnSelectHandler,
    onFilterOperatorSelectHandler,
    onFilterValueOneChangeHandler,
    onFilterValueTwoChangeHandler,
    updateCursor,
    error,
  } = useFilter({
    endpoint: config.apiEndpoints.companies,
    exportIdentifier: "Companies",
    exportEndpoint: "/export/companies",
    queryParams: [{ page: activePage }],
    selectedFilters: selectedFilters,
    setSelectedFilters: setSelectedFilters,
  });

  // useEffect(() => {
  //   const query1Value = selectedFilters[1]?.query_1;
  //   if (Array.isArray(query1Value) && query1Value.length > 0) {
  //     setSelectedSectorId(query1Value[0]?._id || "");
  //   } else {
  //     setSelectedSectorId("");
  //   }
  // }, [selectedFilters]);

  // useEffect(() => {
  //   setSelectedFilters((prevFilters) => {
  //     const updatedFilters = prevFilters?.map((filter) => {
  //       if (filter.field === "category.name") {
  //         return {
  //           ...filter,
  //           searchUrl: selectedSectorId
  //             ? `/get-industry-dd?parent_id=${selectedSectorId}`
  //             : `/get-industry-dd`,
  //         };
  //       }
  //       return filter;
  //     });

  //     return updatedFilters;
  //   });
  //   // eslint-disable-next-line
  // }, [selectedSectorId]);

  useEffect(() => {
    if (records && records?.data) {
      setCompanyList(records);
    }
  }, [records]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  const handleRowClick = (row: any) => {
    setSelectedDescription(row?.name);
    setSelectedCompanyId(row?._id);
  };

  const toggleBookmarkApi = async (row: any) => {
    try {
      const response = await axios.post(config.apiEndpoints.bookmark, {
        entity_id: row?._id,
        entity_type: "Company",
        entity_title: row?.name,
        isBookmarked: !row?.bookmarked,
      });
      if (response?.data) {
        const updatedCompanyList = companyList?.data.map((company: any) => {
          if (company._id === row._id) {
            return {
              ...company,
              bookmarked: !company.bookmarked,
            };
          }
          return company;
        });

        setCompanyList((prevState: any) => ({
          ...prevState,
          data: updatedCompanyList,
        }));

        toast.success(response?.data?.message);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const tableColumnsForSorting = [
    {
      key: "bookmarks",
      label: "Bookmarks",
      sortable: false,
      render: (row: any) => (
        <span
          style={{
            cursor: "pointer",
            color: row?.bookmarked ? "gold" : "gray",
          }}
          className="ms-2"
          onClick={() => toggleBookmarkApi(row)}
        >
          {row?.bookmarked === true ? (
            <FaStar size={18} />
          ) : (
            <FaRegStar size={18} />
          )}
        </span>
      ),
      // width: "20%",
    },
    {
      key: "name",
      label: "Company",
      sortable: true,
      render: (row: any) => (
        <Link to={`/company-detail/${row._id}`}>
          <span className="fw-semibold">{row?.name || "--"}</span>
        </Link>
      ),
      // width: "20%",
    },
    {
      key: "head_quarter",
      label: "Head Quarter",
      render: (row: any) => (
        <>
          <Badge text={`${row?.head_quarter?.name || "--"}`} />
        </>
      ),
    },
    {
      key: "category",
      label: "Industry",
      render: (row: any) => (
        <>
          <span className="text-muted">
            {row?.category
              ? `${row?.category?.parent?.name} > ${row?.category?.name}`
              : "--"}
          </span>
        </>
      ),
    },
    {
      key: "research",
      label: "Performance",
      render: (row: any) => (
        <ScoreBlocks score={row?.research?.all_score || 0} />
      ),
      width: "30%",
    },
  ];

  return (
    <>
      <h3>Companies</h3>

      <section>
        <div className="row">
          <div className="col-12">
            <div className="companies_filter border bg-info-subtle rounded p-4">
              <div className="row">
                <Filterable
                  loading={useFilterLoading}
                  canExport={true}
                  showFilterable={true}
                  selectedFilters={selectedFilters}
                  sortColumn={sortColumn}
                  removeSortColumn={removeSortColumn}
                  resetSorting={resetSorting}
                  sortableFields={[]}
                  queryParams={[]}
                  exportRecords={exportRecords}
                  applyFilters={applyFilters}
                  resetFilter={resetFilter}
                  onFilterColumnSelectHandler={onFilterColumnSelectHandler}
                  onFilterOperatorSelectHandler={onFilterOperatorSelectHandler}
                  onFilterValueOneChangeHandler={onFilterValueOneChangeHandler}
                  onFilterValueTwoChangeHandler={onFilterValueTwoChangeHandler}
                  error={error}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <TabHeader activeTab={activeTab} onTabChange={setActiveTab} />
      <section className="mt-3 mt-lg-4">
        {activeTab === "table" && (
          <div className="row">
            <div className={`col-lg-${table_view_column}`}>
              <div className="card card-border table_layout2">
                <DataTable
                  loading={useFilterLoading}
                  data={companyList?.data}
                  columns={tableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Companies Found",
                  }}
                  sortColumn={sortColumn}
                  onRowClick={(row: any) => handleRowClick(row)}
                />
              </div>
              {config.paginator === "cursor" ? (
                <div className="pagination">
                  {companyList?.meta?.has_previous ? (
                    <Link
                      to={""}
                      onClick={() =>
                        updateCursor(companyList?.meta?.previous_token)
                      }
                      className="btn btn-primary"
                      style={{ marginRight: "5px" }}
                    >
                      Previous
                    </Link>
                  ) : (
                    <button
                      className="btn btn-secondary"
                      style={{ marginRight: "5px" }}
                      disabled
                    >
                      Previous
                    </button>
                  )}
                  {companyList?.meta?.has_next ? (
                    <Link
                      to={""}
                      onClick={() =>
                        updateCursor(companyList?.meta?.next_token)
                      }
                      className="btn btn-primary ml-1"
                    >
                      Next
                    </Link>
                  ) : (
                    <button className="btn btn-secondary" disabled>
                      Next
                    </button>
                  )}
                </div>
              ) : (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={companyList?.total || 0}
                  pageRangeDisplayed={10}
                  itemClass="page-item"
                  linkClass="page-link"
                  onChange={handlePageChange}
                />
              )}
            </div>

            <div className={`col-lg-${details_view_column} ${details_class}`}>
              <div className="card card-border">
                <div className="card-header">Company Detail</div>
                <div className="card-body">
                  {useFilterLoading ? (
                    <Skeleton height={200} width={"100%"} />
                  ) : selectedDescription ? (
                    <>
                      <p>{selectedDescription}</p>
                    </>
                  ) : (
                    <p>Select a company to see details</p>
                  )}
                  <div className="text-end mt-3">
                    <span className="border border-1 border-primary rounded py-1 px-2">
                      <Link
                        title="Link"
                        className="mt-5"
                        to={`/company-detail/${selectedCompanyId}`}
                      >
                        <LinkIcon />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === "states" && (
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-border mb-4">
                <div className="card-body">
                  <h5>States View</h5>
                  {/* Add detailed "States" content here */}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Companies;
