import React, { useEffect, useState } from "react";
import config from "../../config";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PortfolioRegulationIcon, PortfolioSearchIcon } from "../../icons";
import Skeleton from "react-loading-skeleton";
import DataTable from "../../components/common/DataTable";
import { Badge, table_view_column } from "../../components/common/CommonUtils";
import useFilter, { AppliedFiltersInterface } from "../../hooks/useFilter";
import Pagination from "react-js-pagination";
import ScoreBlocks from "../../components/common/ProgressSegmentBar";

const SectorView = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const axios = useAuthenticatedAxios();
  const [industryList, setIndustryList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState<any>([]);
  const [ESGData, setESGData] = useState<any>([]);
  const [industryId, setIndustryId] = useState("");
  const [selcetedIndustry, setSelectedIndustry] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState<
    Array<AppliedFiltersInterface>
  >([
    {
      title: "Industry",
      field: "category_id",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Select industry",
      columns: 1,
      columnType: "autocomplete",
      searchUrl: `/get-industry-dd`,
      searchWithKey: "_id",
      isMulti: true,
    },
  ]);

  const {
    loading: useFilterLoading,
    records,
    fetchRecords,
    sortColumn,
    updateCursor,
  } = useFilter({
    endpoint: config.apiEndpoints.companies,
    exportIdentifier: "Companies",
    exportEndpoint: "/export/companies",
    queryParams: [{ page: activePage }],
    selectedFilters: selectedFilters,
    setSelectedFilters: setSelectedFilters,
  });

  const handleIndustryChange = (id: string) => {
    setIndustryId(id);
    setSelectedFilters([
      {
        title: "Industry",
        field: "category_id",
        operator: "includes_by_id",
        operators: [],
        query_1: id,
        query_2: null,
        placeholder: "Select industry",
        columns: 1,
        columnType: "autocomplete",
        searchUrl: `/get-industry-dd`,
        searchWithKey: "_id",
        isMulti: true,
      },
    ]);
  };
  useEffect(() => {
    if (industryId) {
      fetchIndustryESGData();
      fetchRecords();
    }
    //eslint-disable-next-line
  }, [selectedFilters, industryId]);

  useEffect(() => {
    if (records && records?.data) {
      setCompanyList(records);
    }
  }, [records]);

  const fetchIndustryESGData = async () => {
    setLoading(true);
    try {
      let url = `${config.apiEndpoints.getIndustryESGScore}/${industryId}`;
      const response = await axios.get(url);
      setESGData(response?.data?.esg_score || []);
    } catch (error: any) {
      // toast.error("Error fetching regulations");
    } finally {
      setLoading(false);
    }
  };

  const fetchIndustryList = async (searchQuery = "") => {
    setLoading(true);
    try {
      let url = `/get-industry-dd?parent_id=${id}`;
      if (searchQuery) {
        url += `&q=${encodeURIComponent(searchQuery)}`;
      }
      const response = await axios.get(url);
      setIndustryList(response?.data?.data || []);
      setSelectedIndustry(response?.data?.sectorDetals?.name || "");
      if (response?.data?.data?.length > 0) {
        setIndustryId(response?.data?.data[0]?._id);
        setSelectedFilters([
          {
            title: "Industry",
            field: "category_id",
            operator: "includes_by_id",
            operators: [],
            query_1: response?.data?.data[0]?._id,
            query_2: null,
            placeholder: "Select industry",
            columns: 1,
            columnType: "autocomplete",
            searchUrl: `/get-industry-dd`,
            searchWithKey: "_id",
            isMulti: true,
          },
        ]);
      } else {
        setIndustryId("");
        setCompanyList([]);
      }
    } catch (error: any) {
      // toast.error("Error fetching regulations");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIndustryList();
    //eslint-disable-next-line
  }, [id]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value.trim() === "") {
      fetchIndustryList();
    }
  };

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery) {
      fetchIndustryList(searchQuery);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  const tableColumnsForSorting = [
    {
      key: "name",
      label: "Company",
      sortable: true,
      render: (row: any) => (
        <Link to={`/company-detail/${row._id}`}>
          <span className="fw-semibold">{row?.name || "--"}</span>
        </Link>
      ),
      // width: "20%",
    },
    {
      key: "head_quarter",
      label: "Head Quarter",
      render: (row: any) => (
        <>
          <Badge text={`${row?.head_quarter?.name || "--"}`} />
        </>
      ),
    },
    {
      key: "category",
      label: "Industry",
      render: (row: any) => (
        <>
          <span className="text-muted">
            {row?.category
              ? `${row?.category?.parent?.name} > ${row?.category?.name}`
              : "--"}
          </span>
        </>
      ),
      // width: "20%",
    },
    {
      key: "research",
      label: "Performance",
      render: (row: any) => (
        <ScoreBlocks score={row?.research?.all_score || 0} />
      ),
      width: "30%",
    },
  ];

  return (
    <>
      <>
        <header className="profile-header">
          <ul className="nav nav-line nav-tabs nav-icon nav-light h-100 d-flex">
            <li className="nav-item">
              <button
                className="d-flex align-items-center nav-link h-100 active"
                onClick={() => navigate("/portfolio")}
              >
                <span className="nav-link-text">Industries</span>
              </button>
            </li>
          </ul>
        </header>

        <section className="my_portfolio">
          <div className="row mt-3">
            <div className="col-lg-12">
              <h1 className="text-black fw-semibold mb-3 h4">
                {selcetedIndustry}
              </h1>

              <div className="row">
                <div className="col-lg-3 col-md-5 col-12">
                  <div
                    className="nav flex-column nav-pills border bg-white rounded"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <form className="w-100" onSubmit={handleSearchSubmit}>
                      <div className="search-box">
                        <input
                          className="search-input"
                          type="text"
                          placeholder="Search Here..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <button type="submit" className="search-btn">
                          <PortfolioSearchIcon />
                        </button>
                      </div>
                    </form>
                    {loading ? (
                      [...Array(industryList?.length || 5)]?.map((_, index) => (
                        <div key={index}>
                          <Skeleton height={40} width={"100%"} />
                        </div>
                      ))
                    ) : industryList?.length === 0 ? (
                      <div className="text-center py-3">
                        No industries found
                      </div>
                    ) : (
                      (() => {
                        let isFirstActiveRendered = false;
                        return industryList?.map(
                          (bookmark: any, index: number) => {
                            const isActive = bookmark?._id === industryId;

                            if (isActive && !isFirstActiveRendered) {
                              isFirstActiveRendered = true;
                              return (
                                <Link
                                  key={index}
                                  to={"#"}
                                  className="nav-link active"
                                >
                                  <div className="compeny_logo">
                                    {bookmark?.name?.charAt(0)?.toUpperCase()}
                                  </div>
                                  {bookmark?.name}
                                </Link>
                              );
                            }

                            return (
                              <button
                                key={index}
                                className={`nav-link ${
                                  isActive ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleIndustryChange(bookmark?._id)
                                }
                              >
                                <div className="compeny_logo">
                                  {bookmark?.name?.charAt(0)?.toUpperCase()}
                                </div>
                                {bookmark?.name}
                              </button>
                            );
                          }
                        );
                      })()
                    )}
                  </div>
                </div>
                <div className="col-lg-9 col-md-7 col-12">
                  {(ESGData?.envScore ||
                    ESGData?.soScore ||
                    ESGData?.govScore) && (
                    <div className="row mb-5">
                      <div className="col-12 col-sm-4 col-md-4">
                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                          <div className="d-flex">
                            <div className="feature_style_1_icon me-3">
                              <PortfolioRegulationIcon />
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <h5 className="text_color_1 fw-bold fs-3 mb-0">
                                    {ESGData?.envScore || 0}
                                  </h5>
                                </div>
                              </div>
                              <div className="border_bottom_custom_1 my-1" />
                              <p className="fw-medium text-black">
                                Environmental Score
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4">
                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                          <div className="d-flex">
                            <div className="feature_style_1_icon me-3">
                              <PortfolioRegulationIcon />
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <h5 className="text_color_1 fw-bold fs-3 mb-0">
                                    {ESGData?.soScore || 0}
                                  </h5>
                                </div>
                              </div>
                              <div className="border_bottom_custom_1 my-1" />
                              <p className="fw-medium text-black">
                                Social Score
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4">
                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                          <div className="d-flex">
                            <div className="feature_style_1_icon me-3">
                              <PortfolioRegulationIcon />
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <h5 className="text_color_1 fw-bold fs-3 mb-0">
                                    {ESGData?.govScore || 0}
                                  </h5>
                                </div>
                              </div>
                              <div className="border_bottom_custom_1 my-1" />
                              <p className="fw-medium text-black">
                                Governance Score
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-12">
                    <div className="row">
                      <div className={`col-lg-${table_view_column}`}>
                        <div className="card card-border table_layout2">
                          <DataTable
                            loading={useFilterLoading || loading}
                            data={
                              industryList?.length > 0 ? companyList?.data : []
                            }
                            columns={tableColumnsForSorting}
                            isBorderdTable={false}
                            sortColumn={sortColumn}
                            emptyMessage={{
                              title: "No Companies Found",
                            }}
                          />
                        </div>
                        {config.paginator === "cursor" ? (
                          <div className="pagination">
                            {companyList?.meta?.has_previous ? (
                              <Link
                                to={""}
                                onClick={() =>
                                  updateCursor(
                                    companyList?.meta?.previous_token
                                  )
                                }
                                className="btn btn-primary"
                                style={{ marginRight: "5px" }}
                              >
                                Previous
                              </Link>
                            ) : (
                              <button
                                className="btn btn-secondary"
                                style={{ marginRight: "5px" }}
                                disabled
                              >
                                Previous
                              </button>
                            )}
                            {companyList?.meta?.has_next ? (
                              <Link
                                to={""}
                                onClick={() =>
                                  updateCursor(companyList?.meta?.next_token)
                                }
                                className="btn btn-primary ml-1"
                              >
                                Next
                              </Link>
                            ) : (
                              <button className="btn btn-secondary" disabled>
                                Next
                              </button>
                            )}
                          </div>
                        ) : (
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={companyList?.total || 0}
                            pageRangeDisplayed={10}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={handlePageChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};
export default SectorView;
