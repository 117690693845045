import React from "react";

interface TabHeaderProps {
  tabs?: any;
  activeTab: string;
  onTabChange: (tabValue: string) => void;
}

const TabHeader: React.FC<TabHeaderProps> = ({
  tabs = [
    { label: "Table", value: "table" },
    // { label: "States", value: "states" },
  ],
  activeTab,
  onTabChange,
}) => {
  return (
    <header className="profile-header mt-2 w-auto">
      <ul className="nav nav-tabs nav-icon nav-light h-100 d-flex">
        {tabs.map((tab: any, index: number) => (
          <li className="nav-item" key={index}>
            <button
              className={`d-flex align-items-center nav-link h-100 ${
                activeTab === tab.value ? "active" : ""
              }`}
              onClick={() => onTabChange(tab.value)}
            >
              <span className="nav-link-text">{tab.label}</span>
            </button>
          </li>
        ))}
      </ul>
    </header>
  );
};

export default TabHeader;
