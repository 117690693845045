import React from "react";
import RegulationByIndustry from "../../../assets/img/regulations-by-Industry.png";
import RegionalSplit from "../../../assets/img/regional-split.png";
import RegulationByGrowth from "../../../assets/img/YOY-growth-regulation.png";
import { Link } from "react-router-dom";
import RecentActivity from "../../../components/common/CommonRecentActivity";
import { BlankStarIcon } from "../../../icons";

const RegulationDashboard = () => {
  return (
    <>
      <div>
        <section className="dashboard-industry">
          <div className="row">
            <div className="col-lg-4">
              <div className="companies_filter border bg-info bg-opacity-10 rounded p-4 h-100">
                <h5 className="text-black fw-semibold mb-3">
                  Regulation <strong>70789</strong>
                </h5>
                <div className="row">
                  <div className="col-lg-6">
                    <h6 className="fw-semibold text-black">Industry </h6>
                    <select
                      className="form-select border fw-semibold text-black"
                      defaultValue="all"
                    >
                      <option value="all">All</option>
                      <option value="regulation-dashboard.html">
                        Utilities
                      </option>
                      <option value="regulation-dashboard.html">Energy</option>
                      <option value="regulation-dashboard.html">
                        Materials
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <h6 className="fw-semibold text-black">Sub Industry</h6>
                    <select
                      className="form-select border fw-semibold text-black"
                      defaultValue={"coal"}
                    >
                      <option value="coal">Coal &amp; Consumable Fuels</option>
                      <option value="regulation-dashboard.html">
                        Aluminum
                      </option>
                      <option value="regulation-dashboard.html">
                        Commodity Chemicals
                      </option>
                      <option value="regulation-dashboard.html">
                        Construction Materials
                      </option>
                      <option value="regulation-dashboard.html">Copper</option>
                      <option value="regulation-dashboard.html">
                        Diversified Chemicals
                      </option>
                      <option value="regulation-dashboard.html">
                        Diversified Metals &amp; Mining
                      </option>
                      <option value="regulation-dashboard.html">
                        Electric Utilities
                      </option>
                      <option value="regulation-dashboard.html">
                        Fertilizers &amp; Agricultural Chemicals
                      </option>
                      <option value="regulation-dashboard.html">
                        Forest Products
                      </option>
                      <option value="regulation-dashboard.html">
                        Gas Utilities
                      </option>
                      <option value="regulation-dashboard.html">Gold</option>
                      <option value="regulation-dashboard.html">
                        Independent Power Producers &amp; Energy Traders
                      </option>
                      <option value="regulation-dashboard.html">
                        Industrial Gases
                      </option>
                      <option value="regulation-dashboard.html">
                        Integrated Oil &amp; Gas
                      </option>
                      <option value="regulation-dashboard.html">
                        Metal, Glass and Plastic Containers
                      </option>
                      <option value="regulation-dashboard.html">
                        Multi-Utilities
                      </option>
                      <option value="regulation-dashboard.html">
                        Oil &amp; Gas Drilling
                      </option>
                      <option value="regulation-dashboard.html">
                        Oil &amp; Gas Equipment &amp; Services
                      </option>
                      <option value="regulation-dashboard.html">
                        Oil &amp; Gas Exploration &amp; Production
                      </option>
                      <option value="regulation-dashboard.html">
                        Oil &amp; Gas Refining &amp; Marketing
                      </option>
                      <option value="regulation-dashboard.html">
                        Oil &amp; Gas Storage &amp; Transportation
                      </option>
                      <option value="regulation-dashboard.html">
                        Paper &amp; Plastic Packaging Product &amp; Materials
                      </option>
                      <option value="regulation-dashboard.html">
                        Paper Products
                      </option>
                      <option value="regulation-dashboard.html">
                        Precious Metals &amp; Minerals
                      </option>
                      <option value="regulation-dashboard.html">
                        Renewable Electricity
                      </option>
                      <option value="regulation-dashboard.html">Silver</option>
                      <option value="regulation-dashboard.html">
                        Speciality Chemicals
                      </option>
                      <option value="regulation-dashboard.html">Steel</option>
                      <option value="regulation-dashboard.html">
                        Water Utilities
                      </option>
                      <option value="regulation-dashboard.html">All</option>
                    </select>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <h6 className="fw-semibold text-black">Region</h6>
                    <select
                      className="form-select border fw-semibold text-black"
                      defaultValue={"all"}
                    >
                      <option value="all">All</option>
                      <option value={1}>U.S.</option>
                      <option value={2}>U.K.</option>
                      <option value={2}>South Africa</option>
                      <option value={2}>Australia</option>
                    </select>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <h6 className="fw-semibold text-black">Year</h6>
                    <select
                      className="form-select border fw-semibold text-black"
                      defaultValue={3}
                    >
                      <option value={1}>2021</option>
                      <option value={2}>2022</option>
                      <option value={2}>2023</option>
                      <option value={3}>2024</option>
                    </select>
                  </div>
                  <div className="col-12 mt-3">
                    <button className="btn btn-outline-secondary">
                      <BlankStarIcon />
                      Save to Watchlist
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="companies_filter border bg-white rounded p-4 h-100">
                <h5 className="text-black fw-semibold mb-3">
                  Regulations Overview by Industry{" "}
                </h5>
                <div className="d-flex pb-2">
                  <div className="companies_filter_ind">Approved</div>{" "}
                  <span className="ms-5 fw-semibold badge badge-soft-primary">
                    1260
                  </span>
                </div>
                <div className="d-flex py-2">
                  <div className="companies_filter_ind">In Process</div>{" "}
                  <span className="ms-5 fw-semibold badge badge-soft-primary">
                    577
                  </span>
                </div>
                <div className="d-flex py-2">
                  {" "}
                  <div className="companies_filter_ind">Archived</div>{" "}
                  <span className="ms-5 fw-semibold badge badge-soft-primary">
                    3994+
                  </span>
                </div>
                <div className="d-flex py-2">
                  <div className="companies_filter_ind">Environment</div>{" "}
                  <span className="ms-5 fw-semibold badge badge-soft-primary">
                    766
                  </span>
                </div>
                <div className="d-flex py-2">
                  <div className="companies_filter_ind">Social</div>{" "}
                  <span className="ms-5 fw-semibold badge badge-soft-primary">
                    189
                  </span>
                </div>
                <div className="d-flex pt-2">
                  <div className="companies_filter_ind">Governance</div>{" "}
                  <span className="ms-5 fw-semibold badge badge-soft-primary">
                    512
                  </span>
                </div>
              </div>
            </div>
            <RecentActivity />
          </div>
        </section>
        <section className=" pt-3 pt-lg-5">
          <div className="row">
            <div className="col-lg-4">
              <div className=" border bg-white rounded p-4 h-100">
                <h5 className="text-black fw-semibold mb-3">
                  Regulations by Industry
                </h5>
                <div className="text-center">
                  <img src={RegulationByIndustry} alt="img" />
                  <div className="mt-2">
                    <Link
                      to="/regulation-list"
                      className="text-decoration-underline"
                    >
                      Show More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className=" border bg-white rounded p-4 h-100">
                <h5 className="text-black fw-semibold mb-3">Regional Split</h5>
                <div className="text-center">
                  <img src={RegionalSplit} alt="img" width={200} />
                  <div className="mt-2">
                    <Link
                      to="/regulation-list"
                      className="text-decoration-underline"
                    >
                      Show More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className=" border bg-white rounded p-4 h-100">
                <h5 className="text-black fw-semibold mb-3">
                  Regulations by Industry
                </h5>
                <div className="text-center">
                  <img src={RegulationByGrowth} alt="img" />
                  <div className="mt-2">
                    <Link
                      to="/regulation-list"
                      className="text-decoration-underline"
                    >
                      Show More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default RegulationDashboard;
