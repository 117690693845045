import React, { Fragment, useEffect, useState } from "react";
import config from "../../config";
import { Link, useParams } from "react-router-dom";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import {
  Badge,
  currencyToSymbolConversion,
  formatCurrency,
  formatDate,
} from "../../components/common/CommonUtils";
import esg_risk_rating from "../../assets/img/esg-risk-rating.png";
import DataTable from "../../components/common/DataTable";
import TabHeader from "../../components/common/TabHeader";
import Skeleton from "react-loading-skeleton";
import { Button, Modal } from "react-bootstrap";

const CompanyDetail = () => {
  const { id = "" } = useParams();
  const axios = useAuthenticatedAxios();
  // const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState<any>("");
  const [newsDetails, setNewsDetails] = useState<any>("");
  const [patentDetails, setPatentsDetails] = useState<any>("");
  const [regulationsDetails, setRegulationsDetails] = useState<any>("");
  const [jobDetails, setJobDetails] = useState<any>("");
  const [dealsAndInvestmentDetails, setDealsAndInvestmentDetails] =
    useState<any>("");
  // const [investmentDetails, setInvestmentDetails] = useState<any>("");
  const [companyLoading, setCompanyLoading] = useState(false);
  const [patentLoading, setPatentLoading] = useState(false);
  const [regulationLoading, setRegulationLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState(false);
  const [newsLoading, setNewsLoading] = useState(false);
  const [dealAndInvestmentLoading, setDealAndInvestmentLoading] =
    useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const [hasFinancial, setHasFinancial] = useState(false);
  const [companyList, setCompanyList] = useState<any>([]);
  const [relatedCompanyLoading, setRelatedCompanyLoading] = useState(false);
  const [industryId, setIndustryId] = useState("");
  const [showOverviewModal, setShowOverviewModal] = useState(false);

  const fetchCompanyNewsDetails = async () => {
    try {
      setNewsLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getCompanyNews}?company_id=${id}&limit=5`
      );
      setNewsDetails(response?.data?.data?.data);
    } catch (error) {
    } finally {
      setNewsLoading(false);
    }
  };
  const fetchCompanyInvestmentAndDealsDetails = async () => {
    try {
      setDealAndInvestmentLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getDealsAndInvestmentDetails}?company_id=${id}&limit=5`
      );
      setDealsAndInvestmentDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setDealAndInvestmentLoading(false);
    }
  };

  const fetchCompanyJobDetails = async () => {
    try {
      setJobLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getFilteredJobs}?company_id=${id}&limit=5`
      );
      setJobDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setJobLoading(false);
    }
  };
  const fetchCompanyPatentDetails = async () => {
    try {
      setPatentLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getFilteredPatents}?company_id=${id}&limit=5`
      );
      setPatentsDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setPatentLoading(false);
    }
  };
  const fetchCompanyRegulationsDetails = async () => {
    try {
      setRegulationLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getFilteredRegulations}?company_id=${id}&limit=5`
      );
      setRegulationsDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setRegulationLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchCompanyDetails = async () => {
      try {
        setCompanyLoading(true);
        const response = await axios.get(
          `${config.apiEndpoints.getCompanyDetail}/${id}`
        );
        setCompanyDetails(response?.data?.data);
        setIndustryId(response?.data?.data?.category_id);
      } catch (error) {
      } finally {
        setCompanyLoading(false);
      }
    };

    fetchCompanyDetails();
    fetchCompanyNewsDetails();
    fetchCompanyInvestmentAndDealsDetails();
    fetchCompanyPatentDetails();
    fetchCompanyRegulationsDetails();
    fetchCompanyJobDetails();
    //eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    setHasFinancial(
      companyDetails?.esgScore?.net_income > 0 ||
        companyDetails?.esgScore?.total_revenue > 0 ||
        companyDetails?.esgScore?.total_debt_to_equity > 0 ||
        companyDetails?.esgScore?.ebitda_margin > 0
    );
  }, [companyDetails]);

  const fetchCompanies = async () => {
    setRelatedCompanyLoading(true);
    try {
      let url = `${config.apiEndpoints.getCompanyDropdown}?category_id=${industryId}`;
      const response = await axios.get(url);
      const data = response?.data?.data;
      const randomData = data.sort(() => Math.random() - 0.5).slice(0, 8);

      setCompanyList(randomData || []);
    } catch (error: any) {
      // toast.error("Error fetching regulations");
    } finally {
      setRelatedCompanyLoading(false);
    }
  };

  useEffect(() => {
    if (industryId) {
      fetchCompanies();
    }
    //eslint-disable-next-line
  }, [industryId, id]);

  const riskRating = [
    {
      status: "Negligible",
      min: 0,
      max: 10,
    },
    {
      status: "Low",
      min: 10,
      max: 20,
    },
    {
      status: "Medium",
      min: 20,
      max: 30,
    },
    {
      status: "High",
      min: 30,
      max: 40,
    },
    {
      status: "Severe",
      min: 40,
      max: 100,
    },
  ];

  const jobTableColumnsForSorting = [
    {
      name: "Job Title",
      selector: (row: any) => row.job_title,
      sortable: false,
      key: "job_title",
      label: "Job Title",
    },
    {
      name: "Publisher",
      key: "job_publisher",
      label: "Publisher",
      selector: (row: any) => row.job_publisher,
    },
    {
      name: "Description",
      key: "job_description",
      label: "Description",
      width: "50%",
      render: (row: any) =>
        (row?.job_description?.length > 500
          ? row?.job_description.substring(0, 500) + "..."
          : row?.job_description) || "--",
    },
    {
      name: "Keyword",
      key: "keyword",
      label: "Keyword",
      selector: (row: any) => row.keyword,
    },
    {
      name: "Posted On",
      key: "job_posted_at_datetime_utc",
      label: "Posted On",
      render: (row: any) => formatDate(row?.job_posted_at_datetime_utc),
      width: "12%",
    },
  ];

  const patentTableColumnsForSorting = [
    {
      key: "title",
      label: "Title",
      sortable: true,
      render: (row: any) => (
        <div>
          <div>
            <Link
              target="_blank"
              to={`https://patents.google.com/patent/${row?.app_country}${row?.patent_id}${row?.patent_kind}`}
            >
              <span className="fw-semibold">{row?.patent_title}</span>
            </Link>
          </div>
          <div>
            {row?.patent_type && <Badge text={row?.patent_type} />}
            {row?.company_info?.name && (
              <Badge text={row?.company_info?.name} />
            )}

            {row?.taxonomy_details?.length > 0 &&
              row?.taxonomy_details.map((detail: any, index: number) => (
                <Badge key={index} text={detail?.pillar} />
              ))}
          </div>
        </div>
      ),
      width: "20%",
    },
    {
      name: "Abstract",
      render: (row: any) =>
        (row?.patent_abstract?.length > 500
          ? row?.patent_abstract.substring(0, 500) + "..."
          : row?.patent_abstract) || "--",
      sortable: true,
      key: "patent_abstract",
      label: "Abstract",
      width: "50%",
    },
    {
      name: "taxonomy",
      key: "taxonomy_details",
      label: "Taxonomy",
      render: (row: any) =>
        row?.taxonomy?.length > 0
          ? row.taxonomy.map((item: any, index: number) => (
              <Badge key={index} text={item?.name || "--"} />
            ))
          : "--",
    },

    {
      name: "Patent Date",
      render: (row: any) => formatDate(row?.patent_date) || "--",
      sortable: true,
      key: "patent_date",
      label: "Patent Date",
      width: "10%",
    },
  ];

  const regulationTableColumnsForSorting = [
    {
      key: "title",
      label: "Title",
      sortable: true,
      render: (row: any) => (
        <div>
          {/* Title Link */}
          <div>
            <Link to={row.link} target="_blank" rel="noopener noreferrer">
              <span>{row.title}</span>
            </Link>
          </div>
          {/* Theme, Country, and Texmorny as Badges */}
          <div>
            {row?.theme && <Badge text={row.theme} />}
            {row?.country?.name && <Badge text={row.country.name} />}
            {row?.taxonomy?.map((item: string, index: number) => (
              <Badge key={index} text={item} />
            ))}{" "}
          </div>
        </div>
      ),
    },
  ];
  const investmentTableColumnsForSorting = [
    {
      label: "Organization Name	",
      key: "organization_identifier.value",
      sortable: false,
      render: (row: any) => row?.organization_identifier?.value,
    },
    {
      label: "Funding Round		",
      key: "identifier",
      sortable: false,
      render: (row: any) => row?.funding_round_identifier?.value,
    },
    {
      label: "Money Raised",
      key: "funding_round_money_raised",
      sortable: false,
      render: (row: any) => {
        const currencySymbol =
          currencyToSymbolConversion(
            row?.funding_round_money_raised?.currency
          ) || "--";
        const value = row?.funding_round_money_raised?.value || "";
        const formattedValue = formatCurrency(value).replace(/^[\D]*/g, "");
        return `${currencySymbol}${formattedValue}`;
      },
    },
    {
      label: "Lead Investor	",
      key: "is_lead_investor",
      sortable: false,
      render: (row: any) =>
        row.is_lead_investor === true
          ? "Yes"
          : row.is_lead_investor === false
          ? "No"
          : "-",
    },
    {
      label: "Announced On		",
      key: "announced_on",
      sortable: true,
      render: (row: any) => formatDate(row?.announced_on),
    },
  ];
  const fundTableColumnsForSorting = [
    {
      label: "Transaction Name",
      key: "identifier",
      sortable: false,
      render: (row: any) => row?.identifier?.value,
    },
    {
      label: "Categories",
      key: "funded_organization_categories",
      sortable: false,
      render: (row: any) => {
        const categories = row?.funded_organization_categories;
        return categories
          ? categories?.map((category: any) => category.value).join(", ")
          : "";
      },
    },
    {
      label: "Number of Investors	",
      key: "num_investors",
      sortable: false,
      render: (row: any) => (row?.num_investors ? row?.num_investors : "--"),
    },
    {
      label: "Money Raised",
      key: "short_description",
      sortable: false,
      render: (row: any) => {
        const description = row?.short_description || "";
        const amountMatch = description.match(/\$([\d,]+)/);
        if (!amountMatch || amountMatch.length < 2) return "-";
        const amount = parseFloat(amountMatch[1].replace(/,/g, ""));
        const formattedAmount = formatCurrency(amount);
        return formattedAmount;
      },
    },
    {
      label: "Lead Investors",
      key: "lead_investor_identifiers",
      sortable: false,
      render: (row: any) =>
        row?.lead_investor_identifiers &&
        row?.lead_investor_identifiers?.map((inv: any) => inv?.value).join(","),
    },
    {
      label: "Announced Date	",
      key: "announced_on",
      sortable: true,
      render: (row: any) => formatDate(row?.announced_on),
    },
  ];

  const handleTabChange = (page: string) => {
    const element = document.getElementById(page);
    if (element) {
      const topPosition = element.offsetTop - 100;
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
      setActiveTab(page);
    }
  };
  return (
    <>
      <section className="companies_sec">
        <div className="row mt-4 justify-content-start">
          <div className="col-lg-12">
            <h5 className="fw-semibold mb-0">
              {companyLoading ? (
                <Skeleton height={30} width={600} className="mb-2" />
              ) : companyDetails?.name ? (
                `${companyDetails?.name} ESG Risk Rating Rating Overview`
              ) : (
                ""
              )}
            </h5>

            <TabHeader
              activeTab={activeTab}
              tabs={[
                { label: "Overview", value: "overview" },
                hasFinancial && { label: "Financial", value: "financial" },
                { label: "Patent", value: "patents" },
                { label: "Regulations", value: "regulations" },
                { label: "Jobs", value: "jobs" },
              ].filter(Boolean)}
              onTabChange={handleTabChange}
            />

            <div className="row mt-3">
              <div className="col-lg-8">
                <div className="card p-3 mb-0">
                  <div className="row">
                    <div className="col-lg-6">
                      {companyLoading ? (
                        <Skeleton height={50} width={400} />
                      ) : (
                        <p>
                          <b>Overview</b> <br />{" "}
                          <span className="text-secondary">
                            {companyDetails?.description?.length > 200 ? (
                              <>
                                {companyDetails?.description.substring(0, 200)}
                                ...
                                <span
                                  className="text-primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setShowOverviewModal(true)}
                                >
                                  Read More
                                </span>
                              </>
                            ) : (
                              companyDetails?.description || "--"
                            )}
                          </span>
                        </p>
                      )}
                      {showOverviewModal && (
                        <Modal
                          show={showOverviewModal}
                          onHide={() => setShowOverviewModal(false)}
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title className="text-black">
                              Overview
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <p>
                              {companyDetails?.description ||
                                "No description available."}
                            </p>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={() => setShowOverviewModal(false)}
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                      <div className="d-flex mt-3 mb-2 justify-content-between">
                        {companyLoading ? (
                          <>
                            <Skeleton height={30} width={200} />
                            <Skeleton height={30} width={200} />
                          </>
                        ) : (
                          <>
                            <p>
                              <b>Operating Status</b> <br />{" "}
                              <span className="mt-4 text-secondary">
                                {companyDetails?.operating_status || "--"}
                              </span>
                            </p>
                            <p>
                              <b>HQ</b> <br />
                              <span className="mt-4 text-secondary">
                                {companyDetails?.head_quarter?.name || "--"}{" "}
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                      <div className="d-flex mt-3 mb-2 justify-content-between">
                        {companyLoading ? (
                          <Skeleton height={30} width={200} />
                        ) : (
                          <p>
                            <b>Legal Name</b> <br />{" "}
                            <span className="mt-4 text-secondary">
                              {companyDetails?.legal_name || "--"}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      {companyLoading ? (
                        <Skeleton height={250} width={300} className="ms-3" />
                      ) : (
                        <>
                          <div className="mb-2">
                            <p>
                              <b>Sector</b> <br />{" "}
                              <span className="mt-4 text-secondary">
                                {companyDetails?.category?.parent?.name || "--"}
                              </span>
                            </p>
                          </div>
                          <div className="mb-2">
                            <p>
                              <b>Industry</b> <br />{" "}
                              <span className="mt-4 text-secondary">
                                {companyDetails?.category?.name || "--"}
                              </span>
                            </p>
                          </div>
                          <div className="mb-2">
                            <p>
                              <b>Employment Strength</b> <br />{" "}
                              <span className="mt-4 text-secondary">
                                {companyDetails?.num_employees_enum || "--"}
                              </span>
                            </p>
                          </div>
                          <div className="mb-2">
                            <p>
                              <b>Website</b> <br />{" "}
                              <span className="mt-4 text-secondary">
                                {companyDetails?.website_url || "--"}
                              </span>
                            </p>
                          </div>
                          <div className="mb-2">
                            <p>
                              <b>Phone</b> <br />{" "}
                              <span className="mt-4 text-secondary">
                                {companyDetails?.phone_number || "--"}
                              </span>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="px-2 pt-3">
                        <div className="row">
                          <div className="col-lg-6 ps-lg-0 pe-lg-0">
                            {companyLoading ? (
                              <Skeleton height={200} width={"100%"} />
                            ) : (
                              <div className="companies_sec_border_1 p-3 bg-white h-100">
                                <h6 className="fw-semibold text-black font-s-16">
                                  ESG Risk Rating
                                </h6>
                                <h2 className="fw-semibold text-black">
                                  {companyDetails?.esgScore?.all_score || 0}{" "}
                                  <span className="font-s-16">/100</span>
                                </h2>
                                <ul className="d-flex text-center esg_risk_rating">
                                  {riskRating?.map((rating, index) => (
                                    <li className="" key={index}>
                                      <div
                                        className={
                                          companyDetails?.esgScore?.all_score >=
                                            rating.min &&
                                          companyDetails?.esgScore?.all_score <=
                                            rating.max
                                            ? "bg_alart_3"
                                            : "bg_alart_1"
                                        }
                                      >
                                        {rating?.status}
                                      </div>
                                      <span>
                                        {rating?.min}-{rating?.max}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                                <p className="mt-3">
                                  Calculated using our{" "}
                                  <Link
                                    to={"#"}
                                    className="text-decoration-underline"
                                  >
                                    ESG Risk Rating Methodology
                                  </Link>
                                </p>
                                <p className="mt-1">
                                  Last Updated:{" "}
                                  {formatDate(companyDetails?.updatedAt) ||
                                    "--"}
                                </p>
                                <p className="mt-1">
                                  <Link
                                    to={"#"}
                                    className="text-decoration-underline"
                                  >
                                    View Company’s Business Model
                                  </Link>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-6 ps-lg-0 pe-lg-0">
                            {companyLoading ? (
                              <Skeleton height={200} width={"100%"} />
                            ) : (
                              <div className="companies_sec_border_2 p-3 bg-white">
                                <h6 className="fw-semibold text-black font-s-16">
                                  ESG Risk Rating Distribution
                                </h6>
                                <img
                                  src={esg_risk_rating}
                                  className="img-fluid"
                                  alt="logo"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-0 card">
                  <div className="border bg-info bg-opacity-10 rounded-top p-2">
                    <h5 className="text-black fw-semibold mb-0 text-center">
                      Top ESG News
                    </h5>
                  </div>
                  <div
                    className="p-3"
                    style={{ height: "69vh", overflowY: "scroll" }}
                  >
                    {newsLoading ? (
                      // Show skeleton loaders while news is loading
                      <>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <Fragment key={index}>
                            <div className="col-12 mb-3">
                              <div className="border bg-white bg-opacity-10 px-3 py-2">
                                <div className="row">
                                  <div className="col-lg-3 col-3">
                                    <Skeleton height={80} width={80} />
                                  </div>
                                  <div className="col-lg-9 col-9">
                                    <h6>
                                      <Skeleton height={20} width={"80%"} />
                                    </h6>
                                    <p>
                                      <Skeleton height={15} width={"95%"} />
                                      <Skeleton height={15} width={"90%"} />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                      </>
                    ) : newsDetails?.length > 0 ? (
                      // Show news items when loaded
                      newsDetails.map((news: any, index: number) => (
                        <Fragment key={index}>
                          <div className="col-12 mb-3">
                            <div className="border bg-white bg-opacity-10 px-3 py-2">
                              <div className="row">
                                <div className="col-lg-3 col-3">
                                  <img
                                    src={news?.image}
                                    alt="img"
                                    style={{
                                      height: "80px",
                                      width: "80px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                                <div className="col-lg-9 col-9">
                                  <h6 className="fw-semibold text-black">
                                    <Link to={news?.url}>
                                      {news?.title.length > 70
                                        ? news?.title.substring(0, 70) + "..."
                                        : news?.title}
                                    </Link>
                                  </h6>
                                  <p>
                                    {news?.summary.length > 100
                                      ? news?.summary.substring(0, 100) + "..."
                                      : news?.summary}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ))
                    ) : (
                      // Show a message if no news is available
                      <div className="col-12 text-center mt-4">
                        <p>No news available.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Deals */}
          <div className="col-12 mt-5" id="patents">
            <div className="card card-border table_layout2">
              <div className="position-relative">
                <div className="card-header">
                  <h4>
                    <b>Funding Round</b>
                  </h4>
                </div>
                <DataTable
                  loading={dealAndInvestmentLoading}
                  data={dealsAndInvestmentDetails?.fund?.data || []}
                  columns={fundTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Deals Found",
                  }}
                />
              </div>
            </div>
          </div>
          {/* Investment */}
          <div className="col-12 mt-5" id="patents">
            <div className="card card-border table_layout2">
              <div className="position-relative">
                <div className="card-header">
                  <h4>
                    <b>Investment</b>
                  </h4>
                </div>
                <DataTable
                  loading={dealAndInvestmentLoading}
                  data={dealsAndInvestmentDetails?.investment?.data || []}
                  columns={investmentTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Investment Found",
                  }}
                />
              </div>
            </div>
          </div>
          {/* Patent */}
          <div className="col-12 mt-5" id="patents">
            <div className="card card-border table_layout2">
              <div className="position-relative">
                <div className="card-header">
                  <h4>
                    <b>Patent</b>
                  </h4>
                  <div className="text-end">
                    {patentDetails?.length > 0 ? (
                      <Link to={`/dataset/patent/${companyDetails?._id}`}>
                        View All
                      </Link>
                    ) : (
                      <Link to={`/dataset/patent`}>View All</Link>
                    )}
                  </div>
                </div>
                <DataTable
                  loading={patentLoading}
                  data={patentDetails || []}
                  columns={patentTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Patent Found",
                  }}
                />
              </div>
            </div>
          </div>

          {/* Regulation */}
          <div className="col-12 mt-5" id="regulations">
            <div className="card card-border table_layout2">
              <div className="position-relative">
                <div className="card-header">
                  <h4>
                    <b>Regulation</b>
                  </h4>
                  <div className=" text-end">
                    {regulationsDetails?.length > 0 ? (
                      <Link to={`/regulation-list/${companyDetails?._id}`}>
                        View All
                      </Link>
                    ) : (
                      <Link to={`/regulation-list`}>View All</Link>
                    )}
                  </div>
                </div>
                <DataTable
                  loading={regulationLoading}
                  data={regulationsDetails || []}
                  columns={regulationTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Regulation Found",
                  }}
                />
              </div>
            </div>
          </div>

          {/* JOB */}

          <div className="col-12 mt-5" id="jobs">
            <div className="card card-border table_layout2">
              <div className="position-relative">
                <div className="card-header">
                  <h4>
                    <b>Job</b>
                  </h4>
                  <div className="text-end">
                    {jobDetails?.length > 0 ? (
                      <Link to={`/dataset/jobs/${companyDetails?._id}`}>
                        View All
                      </Link>
                    ) : (
                      <Link to={`/dataset/jobs`}>View All</Link>
                    )}
                  </div>
                </div>
                <DataTable
                  loading={jobLoading}
                  data={jobDetails || []}
                  columns={jobTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Job Found",
                  }}
                />
              </div>
            </div>
          </div>

          {/* OTHER COMPANIES */}
          <div className="col-12 mt-4" id="company">
            <div className="row p-3">
              {relatedCompanyLoading || companyLoading ? (
                // Show skeleton loaders while the related companies data is loading
                Array.from({ length: 8 }).map((_, index) => (
                  <div className="col-lg-3 col-md-6 col-sm-8 mb-3" key={index}>
                    <Skeleton
                      height={150}
                      width={"100%"}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                ))
              ) : companyList?.length > 0 ? (
                // Show related companies
                companyList
                  ?.filter((company: any) => !id.includes(company._id))
                  .map((company: any, index: number) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-8 mb-3 text-center"
                      key={index}
                    >
                      <div className="border rounded bg-white p-3 h-100">
                        <Link to={`/company-detail/${company?._id}`}>
                          <span className="fw-semibold">
                            {company?.name || "--"}
                          </span>
                          <br />
                          <Badge
                            text={`${company?.head_quarter?.name || "--"}`}
                          />
                          <br />
                          <div className="text-black mt-2">
                            <span className="fw-semibold">
                              E Score :{" "}
                              {company?.latest_research?.e_score || "0"}
                            </span>
                            <br />
                            <span className="fw-semibold">
                              S Score :{" "}
                              {company?.latest_research?.s_score || "0"}
                            </span>
                            <br />
                            <span className="fw-semibold">
                              G Score :{" "}
                              {company?.latest_research?.g_score || "0"}
                            </span>
                            <br />
                            <span className="fw-semibold">
                              Overall Score :{" "}
                              {company?.latest_research?.all_score || "0"}
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))
              ) : (
                // Show message if no related companies are available
                <div className="col-12 text-center mt-4">
                  <p>No related companies available.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CompanyDetail;
