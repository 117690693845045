import React, { useState, useEffect, useRef } from "react";
import { useAuthenticatedAxios } from "../../hooks/useAxios"; // Custom Axios hook
import config from "../../config";
import { PortfolioLinkRoundArrowIcon } from "../../icons";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
interface regulationsProps {
  taxonomy: string;
  companyId: string;
}

const Regulations = ({ taxonomy, companyId }: regulationsProps) => {
  const axios = useAuthenticatedAxios();
  const [regulationsData, setRegulationsData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const page = 1;
  const length = regulationsData?.data?.length || 5;
  const prevCompanyIdRef = useRef<string | null>(null);

  const fetchRegulations = async () => {
    setLoading(true);
    try {
      let url = `${config.apiEndpoints.getFilteredRegulations}?page=${page}&limit=5&company_id=${companyId}`;
      if (taxonomy && prevCompanyIdRef.current === companyId) {
        url += `&taxonomy=${taxonomy}`;
      }

      const response = await axios.get(url);
      setRegulationsData(response?.data?.data || []);
    } catch (error: any) {
      // toast.error("Error fetching regulations");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchRegulations();
    }
    prevCompanyIdRef.current = companyId;
    //eslint-disable-next-line
  }, [taxonomy, companyId]);

  return (
    <div className="row">
      <div className="col-sm-6 col-12 col-lg-12">
        <div className="style_2 border bg-white rounded-bottom">
          <div className="table-responsive">
            <table className="table mb-0">
              <tbody>
                {loading ? (
                  [...Array(length)].map((_, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton height={20} width={500} className="mb-2" />
                      </td>
                      <td>
                        <Skeleton height={20} width={30} className="mb-2" />
                      </td>
                    </tr>
                  ))
                ) : regulationsData.length > 0 ? (
                  regulationsData?.map((regulation: any, index: number) => (
                    <tr key={index}>
                      <td>{regulation?.title || "No title available"}</td>
                      <td>
                        <div className="text-end">
                          <Link
                            className="dashboard_arow_2"
                            to={regulation?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <PortfolioLinkRoundArrowIcon />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>No regulations found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="text-end mt-3">
          <Link
            to="#"
            className="text-decoration-underline fw-semibold"
            onClick={() => fetchRegulations("filterKey", "filterValue")}
          >
            Show More Regulations
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Regulations;
