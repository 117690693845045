import React from "react";
import LandingLayout from "../layouts/landing/LandingLayout";

const NotFound = () => {
  return (
    <>
      <LandingLayout>
        <div className="row">
          <div className="404_sec my-lg-7 my-5">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-4 col-sm-6">
                  <div className="text-center" />
                </div>
                <div className="col-lg-4 col-sm-6 text-center text-sm-start">
                  <h1 className="fw-semibold text_purple mb-3">404</h1>
                  <p className="mb-4 text-secondary">
                    Sorry, The reuested page cannot be found. Try finding with
                    another name.
                  </p>
                  <a
                    className="btn btn_overview_blue"
                    title="Return To Home"
                    href="/"
                  >
                    Return To Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LandingLayout>
    </>
  );
};

export default NotFound;
