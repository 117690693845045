import { createContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { AuthContextType } from "../types/AuthContextType";
import config from "../config";
import { toast } from "react-toastify";
import { useAuthenticatedAxios } from "../hooks/useAxios";

export const AuthContext = createContext<AuthContextType>({
  user: null,
  token: null,
  login: async (user) => {},
  logout: async () => {},
  isAuthenticated: () => {
    return false;
  },
  hasRole: (role: string) => {
    return false;
  },
  updateUser: (user: any) => {},
});

export const AuthProvider = ({ children }: any) => {
  const AuthenticatedAxios = useAuthenticatedAxios();
  const [user, setUser] = useLocalStorage("user", null);
  const [token, setToken] = useLocalStorage("token", null);

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (user: any) => {
    // setUser(user); //to navigate to dashboard uncomment this
    if (user?.is_verified === 1) {
      const newToken = user?.token;
      setToken(newToken);
      try {
        const response = await AuthenticatedAxios.get(
          config.apiEndpoints.getCurrentUser,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${newToken}`,
            },
          }
        );
        if (response?.data) {
          setUser(response?.data?.data);
          navigate(config.authSuccessRedirect);
        } else {
          toast.error("Failed to fetch user data. Please try again.");
        }
      } catch {
        toast.error("An error occurred. Please try again.");
      }
    } else {
      toast.error("Email is not verified");
    }
  };

  const updateUser = (updatedUser: any) => {
    setUser(updatedUser);
    localStorage.setItem("user", JSON.stringify(updatedUser));
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate(config.authRedirect, { replace: true });
  };

  const isAuthenticated = () => {
    return user && token;
  };

  const hasRole = (role: string): boolean => {
    return user.role === role;
  };

  const value = useMemo(
    () => ({
      user,
      token,
      isAuthenticated,
      hasRole,
      login,
      logout,
      updateUser,
    }),
    //eslint-disable-next-line
    [user, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
