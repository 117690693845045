import React, { useEffect, useState } from "react";
import { formatDate } from "../../components/common/CommonUtils";
import config from "../../config";
import DataTable from "../../components/common/DataTable";
import Pagination from "react-js-pagination";
import useFilter, { AppliedFiltersInterface } from "../../hooks/useFilter";
import Filterable from "../../Filterable";
import { patentfilterableFields } from "../../Filterable/FilterableFields";
import { Link, useParams } from "react-router-dom";
import TabHeader from "../../components/common/TabHeader";
import Skeleton from "react-loading-skeleton";
import { Badge } from "../../components/common/CommonUtils";
import { LinkIcon } from "../../icons";
import {
  table_view_column,
  details_view_column,
  details_class,
} from "../../components/common/CommonUtils";

const Patent = () => {
  const { companyId } = useParams();
  const [activePage, setActivePage] = useState(1);
  const [activeTab, setActiveTab] = useState("table");
  const [selectedPatent, setSelectedPatentDetails] = useState<any | null>(null); // Set selectedPatent as an object
  const [readMore, setReadmore] = useState(false);

  //eslint-disable-next-line
  const [showFilterable, setShowFilterable] = useState(true);

  const [selectedFilters, setSelectedFilters] = useState<
    Array<AppliedFiltersInterface>
  >([
    {
      title: "Company",
      field: "company.name",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: null,
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/company-dropdown",
      searchWithKey: "name",
      isMulti: true,
    },
    {
      title: "Piller",
      field: "taxonomy.pillar",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      labelField: "key",
      valueField: "value",
      placeholder: "Select piller",
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/get-piller",
      isMulti: true,
      searchWithKey: "value",
    },
    {
      title: "Published On",
      field: "patent_date",
      operator: "between",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Select date",
      columns: 1,
      columnType: "datetime",
    },
  ]);

  const {
    loading: useFilterLoading,
    records,
    //selectedFilters,
    sortColumn,
    removeSortColumn,
    resetSorting,
    applyFilters,
    exportRecords,
    addFilter,
    clearFilter,
    removeFilter,
    resetFilter,
    onFilterColumnSelectHandler,
    onFilterOperatorSelectHandler,
    onFilterValueOneChangeHandler,
    onFilterValueTwoChangeHandler,
    updateCursor,
    error,
  } = useFilter({
    endpoint: companyId
      ? config.apiEndpoints.getFilteredPatents + "?company_id=" + companyId
      : config.apiEndpoints.patents,
    exportIdentifier: "Patents",
    exportEndpoint: "/export/patents",
    queryParams: [{ page: activePage }],
    selectedFilters: selectedFilters,
    setSelectedFilters: setSelectedFilters,
  });

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    if (companyId && records?.data?.length > 0) {
      setSelectedFilters((prev: any) =>
        prev.map((filter: any) => {
          if (filter?.field === "company_id") {
            return {
              ...filter,
              ["query_1"]: [
                {
                  name: records?.data[0]?.company?.name,
                  id: records?.data[0]?.company?._id,
                },
              ],
            };
          }
          return filter;
        })
      );
    }
    //eslint-disable-next-line
  }, [records?.data?.length, companyId]);

  const handleRowClick = (row: any) => {
    // Pass the entire row object to selectedPatent
    setSelectedPatentDetails(row);
  };
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const handleReadMoreToggle = (rowIndex: number) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };

  const [iframeSrc, setIframeSrc] = useState("");

  const handleViewInIframe = (row: any) => {
    const url = `https://patents.google.com/patent/${row?.app_country}${row?.patent_id}${row?.patent_kind}`;
    setIframeSrc(url);
  };


  const columns = [
    {
      key: "patent_title",
      label: "Title",
      sortable: true,
      render: (row: any) => (
        <div>
          {/* Title Link */}
          <div   onClick={() => handleViewInIframe(row)}   >
              <span className="fw-semibold">{row.patent_title || "--"}</span>
          </div>

          {/* Abstract with Read More/Read Less */}
          <div>
            <span>
              {expandedRow === records?.data.indexOf(row)
                ? row.patent_abstract || "--"
                : (row.patent_abstract || "--")
                    .split(" ")
                    .slice(0, 10)
                    .join(" ")}
              {(row.patent_abstract || "").split(" ").length > 10 &&
                row.patent_abstract && (
                  <>
                    <button
                      className="btn btn-link p-0 ms-1"
                      onClick={() =>
                        handleReadMoreToggle(records?.data.indexOf(row))
                      }
                    >
                      {expandedRow === records?.data.indexOf(row)
                        ? "Read Less"
                        : "Read More"}
                    </button>
                  </>
                )}
              </span>
            </div>
    
            {/* Badges */}
            <div>
              {row?.patent_type && <Badge text={row.patent_type} />}
              {row?.company?.name && <Badge text={row.company.name} />}
              {row?.taxonomy?.length > 0 &&
                Array.from(
                  new Map(row.taxonomy.map((item: any) => [item.pillar, item])).values()
                ).map((detail: any, index: number) => (
                  <Badge key={index} text={detail.pillar} />
                ))}


            </div>

        </div>
      ),
      width: "50%",
    },
    {
      name: "Inventors",
      key: "inventors",
      label: "Inventors",
      render: (row: any) => row?.inventors || "--",
      sortable: true,
      width: "20%",
    },
    {
      name: "Assignee",
      key: "assignees",
      label: "Assignee",
      render: (row: any) => row?.assignees || "--",
      sortable: true,
      width: "20%",
    },
    {
      name: "Patent Date",
      key: "patent_date",
      label: "Patent Date",
      render: (row: any) => formatDate(row?.patent_date) || "--",
      sortable: true,
      width: "20%",
    },
  ];

  return (
    <>
      <h3>Patents</h3>
      <div>
        <section>
          <div className="row">
            <div className="col-12">
              <div className="companies_filter border bg-info-subtle rounded p-4">
                <Filterable
                  loading={useFilterLoading}
                  canExport={true}
                  showFilterable={showFilterable}
                  selectedFilters={selectedFilters}
                  sortColumn={sortColumn}
                  removeSortColumn={removeSortColumn}
                  resetSorting={resetSorting}
                  filterableFields={patentfilterableFields}
                  sortableFields={[]}
                  queryParams={[]}
                  exportRecords={exportRecords}
                  applyFilters={applyFilters}
                  addFilter={addFilter}
                  clearFilter={clearFilter}
                  removeFilter={removeFilter}
                  resetFilter={resetFilter}
                  onFilterColumnSelectHandler={onFilterColumnSelectHandler}
                  onFilterOperatorSelectHandler={onFilterOperatorSelectHandler}
                  onFilterValueOneChangeHandler={onFilterValueOneChangeHandler}
                  onFilterValueTwoChangeHandler={onFilterValueTwoChangeHandler}
                  error={error}
                />
              </div>
            </div>
          </div>
        </section>

        <div className="row">
          <div className="col-12">
            <section className="mt-5">
              <TabHeader activeTab={activeTab} onTabChange={setActiveTab} />
              <section>
                {activeTab === "table" && (
                  <div className="row">
                    <div className={`col-lg-${table_view_column}`}>
                      <section className="mt-3 mt-lg-4">
                        <div className="card card-border mb-4 table_layout2">
                          <div className="position-relative">
                            <DataTable
                              loading={useFilterLoading}
                              data={records?.data}
                              columns={columns}
                              isBorderdTable={false}
                              emptyMessage={{
                                title: "No Patents Found",
                              }}
                              sortColumn={sortColumn}
                              onRowClick={handleRowClick} // Pass full row data on click
                            />
                          </div>
                        </div>
                        {config.paginator === "cursor" ? (
                          <div className="pagination">
                            {records?.meta?.has_previous ? (
                              <button
                                onClick={() =>
                                  updateCursor(records?.meta?.previous_token)
                                }
                                className="btn btn-primary"
                                style={{ marginRight: "5px" }}
                              >
                                Previous
                              </button>
                            ) : (
                              <button
                                className="btn btn-secondary"
                                disabled
                                style={{ marginRight: "5px" }}
                              >
                                Previous
                              </button>
                            )}
                            {records?.meta?.has_next ? (
                              <button
                                onClick={() =>
                                  updateCursor(records?.meta?.next_token)
                                }
                                className="btn btn-primary ml-1"
                              >
                                Next
                              </button>
                            ) : (
                              <button className="btn btn-secondary" disabled>
                                Next
                              </button>
                            )}
                          </div>
                        ) : (
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={records?.total || 0}
                            pageRangeDisplayed={10}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={handlePageChange}
                          />
                        )}
                      </section>
                    </div>

                    <div
                      className={`col-lg-${details_view_column} ${details_class}`}
                    >
                      <div className="card card-border mt-4">
                        <div className="card-header">Detail</div>
                        <div className="card-body">
                          {useFilterLoading ? (
                            <Skeleton height={200} width={"100%"} />
                          ) : selectedPatent ? (
                            <div>
                              <h3>
                                <Link
                                  target="_blank"
                                  to={`https://patents.google.com/patent/${selectedPatent?.app_country}${selectedPatent?.patent_id}${selectedPatent?.patent_kind}`}
                                >
                                  <span className="fw-semibold">
                                    {selectedPatent.patent_title}
                                  </span>
                                </Link>
                              </h3>
                              <p>
                                {readMore
                                  ? selectedPatent?.patent_abstract
                                  : selectedPatent?.patent_abstract.substring(
                                      0,
                                      200
                                    )}
                                ...
                                {selectedPatent?.patent_abstract?.length >
                                  200 && (
                                  <Link
                                    to={""}
                                    className="mt-5"
                                    onClick={(): void => setReadmore(!readMore)}
                                  >
                                    {readMore ? "Read Less" : "Read More"}
                                  </Link>
                                )}
                              </p>
                            </div>
                          ) : (
                            <p>Select a patent to see details here.</p>
                          )}
                          <div className="text-end mt-3">
                            <span className="border border-1 border-primary rounded py-1 px-2">
                              <Link
                                title="Link"
                                className="mt-5"
                                to={`https://patents.google.com/patent/${selectedPatent?.app_country}${selectedPatent?.patent_id}${selectedPatent?.patent_kind}`}
                                target="_blank"
                              >
                                <LinkIcon />
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "states" && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card card-border mb-4">
                        <div className="card-body">
                          <h5>Details of the selected patent</h5>
                          {/* Add detailed "States" content here */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </section>
          </div>
        </div>
      </div>

      {iframeSrc && (
        <div style={{ marginTop: "20px" }}>
          <h4>Patent Viewer</h4>
          <iframe
            src={iframeSrc}
            title="Patent Viewer"
            style={{ width: "100%", height: "500px", border: "1px solid #ccc" }}
          ></iframe>
        </div>
      )}

    </>
  );
};

export default Patent;
