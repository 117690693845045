import React, { ChangeEvent, FormEvent, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import config from "../../config";
import { useAxios } from "../../hooks/useAxios";
import AuthRightContent from "../../layouts/AuthRightContent";
import logo from "../../assets/img/logo.png";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const axios = useAxios();
  const { token, email } = useParams();
  const defaultValues = {
    email: email,
    token: token,
    password: "",
    password_confirmation: "",
  };
  const [detail, setDetails] = useState(defaultValues);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (detail?.password === "") {
      toast.error("Please enter new password");
      return;
    }

    if (detail?.password_confirmation === "") {
      toast.error("Please enter confirm password");
      return;
    }
    if (detail?.password_confirmation !== detail?.password) {
      toast.error("New password and confirm password do not match");
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        config.apiEndpoints.updatePassword,
        detail
      );
      if (response?.data?.status === 200) {
        toast.success("Password updated successfully");
        setDetails(defaultValues);
        navigate("/login");
      }
    } catch (error: any) {
      toast.error("Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setDetails((oldDetail) => ({
      ...oldDetail,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <>
      <div className="row align-items-center sign-up1-sec">
        <div className="col-xl-4 col-lg-5 col-md-6 position-relative px-0">
          <div
            className="bg-white align-content-center h-735p"
            style={{ minHeight: 735 }}
          >
            <div className="pt-0 px-lg-7 px-5 pb-5">
              <div className="text-center mb-6">
                <a className="navbar-brand" title="NeoImpact" href="/">
                  <img
                    className="brand-img d-inline-block"
                    src={logo}
                    width={144}
                    height={36}
                    alt="NeoImpact"
                    loading="lazy"
                  />
                </a>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-label-group mb-4">
                  <h4 className="my-0">Update Password</h4>
                </div>

                <div
                  className="form-floating mb-4"
                  style={{ position: "relative" }}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="New Password"
                    value={detail?.password}
                    required
                    onChange={handleOnChange}
                  />
                  <Link
                    to="#"
                    onClick={handleTogglePassword}
                    className="input-suffix text-muted"
                    title={showPassword ? "Hide Password" : "View Password"}
                    style={{ position: "absolute", top: "16px", right: "5px" }}
                  >
                    {detail?.password !== "" &&
                      (showPassword ? <FaEyeSlash /> : <FaEye />)}
                  </Link>
                  <label htmlFor="floatingPassword">New Password</label>
                </div>
                <div
                  className="form-floating mb-4"
                  style={{ position: "relative" }}
                >
                  <input
                    type={"password"}
                    name="password_confirmation"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Confirm Password"
                    value={detail?.password_confirmation}
                    required
                    onChange={handleOnChange}
                  />
                  <label htmlFor="floatingPassword">Confirm Password</label>
                </div>
                <div className="mb-4">
                  <button
                    className="as_info_btn  btn-uppercase btn-block"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting && (
                      <Spinner
                        style={{
                          height: "15px",
                          width: "15px",
                          marginTop: "2px",
                          marginRight: "2px",
                        }}
                      />
                    )}
                    Update Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <AuthRightContent />
      </div>
    </>
  );
};

export default UpdatePassword;
