import { useEffect, useRef, useState } from "react";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import Select from "react-dropdown-select";

export const unAuthenticatedRoute = [
  `/sanctum/csrf-cookie`,
  `${process.env.REACT_APP_API_URL}/api/sign-in`,
  `${process.env.REACT_APP_API_URL}/api/signup`,
  `${process.env.REACT_APP_API_URL}/api/forgot-password`,
  `${process.env.REACT_APP_API_URL}/api/forgot-password`,
  `${process.env.REACT_APP_API_URL}/api/reset-password`,
  `${process.env.REACT_APP_API_URL}/api/verify-otp`,
  `${process.env.REACT_APP_API_URL}/api/resend-otp`,
];
export const pageRangeDisplayed = 10;
export function validateEmail(email: string) {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(email);
}
export function getYearsDropdown() {
  const year = 2021; //new Date().getFullYear() - 6;
  const maxYear = new Date().getFullYear() + 1;
  return Array.from({ length: maxYear - year }, (value, index) => year + index);
}

export const constants = {
  tcUrl: "https://www.grandviewresearch.com/info/terms-of-use",
  privacyUrl: "https://www.grandviewresearch.com/info/privacy-policy",
};

export function formatDate(dateString: string): string {
  // Ensure the date string is valid
  // if (!dateString) {
  //   throw new Error("Invalid date string");
  // }

  const date = new Date(dateString);

  // Check if the date is valid
  // if (isNaN(date.getTime())) {
  //   throw new Error("Invalid date");
  // }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit',
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
}

export const formatCurrency = (value: number) => {
  if (typeof value !== "number") return value; // Ensure value is a number
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const country = {
  USD: "en-US",
  INR: "en-IN",
  EUR: "en-EU",
  JPY: "ja-JP",
  GBP: "en-GB",
  AUD: "en-AU",
  CAD: "en-CA",
  CHF: "de-CH",
  CNY: "zh-CN",
  SEK: "sv-SE",
  NZD: "en-NZ",
};

export const formatNumber = (
  value: number | { value: string; currency: string }
): string => {
  if (typeof value === "number") {
    return new Intl.NumberFormat("en-IN").format(value);
  } else if (typeof value === "object" && value.value && value.currency) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: value.currency,
    }).format(Number(value.value));
  }

  return ""; // Return an empty string if the format doesn't match
};

// Example function that calls formatNumberFromString
export const formatNumberFromString = (
  value: string,
  currency = "USD"
): string => {
  let match = value.match(/\$\d+(\.\d{1,2})?/);

  if (match) {
    let amount = parseFloat(match[0].replace("$", ""));
    let formattedAmount = formatNumber({
      value: amount.toString(),
      currency: currency,
    });
    return value.replace(match[0], formattedAmount);
  }

  return value;
};

export const Badge = ({
  text = "--", // Default text if none provided
  backgroundColor = "#cff4fc", // Default background color
  textColor = "black", // Default text color
  fontSize = "12px", // Default font size
  padding = "5px 10px", // Default padding
  borderRadius = "12px", // Default border radius
  fontWeight = "bold", // Default font weight
  className = "", // Additional classes for custom styles
}) => {
  return (
    <span
      className={`badge ${className}`}
      style={{
        backgroundColor,
        color: textColor,
        fontSize,
        padding,
        borderRadius,
        fontWeight,
        display: "inline-block",
      }}
    >
      {text}
    </span>
  );
};

export const DropdownComponent = ({
  searchBy,
  valueField,
  labelField,
  searchUrl,
  label,
  options,
  inputChange,
  handleChange,
  value,
  searchable,
  isMulti,
  isloading,
}: any) => {
  const axios = useAuthenticatedAxios();
  const [searchOptions, setSearchOptions] = useState<any[]>(options || []);
  const timerRef = useRef<number | undefined>(undefined); // Timer reference for debounce
  const [loading, setLoading] = useState(false);
  // Handle search input change with debouncing
  const handleInputChange = async ({ value }: any) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear the previous timer
    }

    timerRef.current = window.setTimeout(async () => {
      setLoading(true);
      try {
        if (!value) {
          setSearchOptions([]); // Clear options if input is empty
          inputChange(""); // Notify parent about the cleared input
          return;
        }
        // Trigger the search API call (adjust based on your API structure)
        const response = await axios.get(`${searchUrl}?q=${value}`);

        // Update the search options with the API response
        setSearchOptions(response?.data?.data || []);
        inputChange(value);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }, 100);
  };

  useEffect(() => {
    // Cleanup the timer on component unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div>
      <Select
        searchBy={searchBy || "name"}
        valueField={valueField || "name"}
        labelField={labelField || "name"}
        options={searchOptions} // Use searchOptions for dynamic options
        values={value || []}
        onChange={(selected: any) => handleChange(selected || [])}
        handleKeyDownFn={(e: any) => handleInputChange(e?.event?.target)}
        searchable={searchable || true}
        multi={isMulti || false}
        placeholder={`Select ${label}`}
        className="form-control border text-black font-s-14 rounded header-s-input ml-5"
        loading={isloading || loading}
        onDropdownOpen={() => handleInputChange({ value: value || "" })}
        clearable={true}
      />
    </div>
  );
};

export function currencyToSymbolConversion(currency: string): string {
  let symbol;
  switch (currency) {
    case "USD":
      symbol = "$";
      break;
    case "TWD":
      symbol = "NT$";
      break;
    case "GBP":
      symbol = "£";
      break;
    default:
      symbol = currency;
      break;
  }
  return symbol;
}

export const table_view_column = process.env.REACT_APP_TABLE_VIEW_COLUMN || 12;
export const details_view_column =
  process.env.REACT_APP_DETAILS_VIEW_COLUMN || 0;
export const details_class = process.env.REACT_APP_DETAILS_CLASS || "d-none";
