import React from "react";
const AuthRightContent = () => {
  return (
    <>
      <div className="col-xl-8 col-lg-7 col-md-6 position-relative px-0">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="d-none d-md-block px-4">
              <h4 className="fw-semibold font-s-20 mb-4">
                Sign up to explore ESG insights and analytics powered by
                NeoImpact.
              </h4>
              <div className="">
                <ul className="">
                  <li className="mb-3">
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/check_bg.png"
                      width={16}
                      height={16}
                      className="me-2 align-2"
                      alt="Newimpact check icn"
                      loading="lazy"
                    />
                    Over 4,000 companies across 11 GICS sectors
                  </li>
                  <li className="mb-3">
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/check_bg.png"
                      width={16}
                      height={16}
                      className="me-2 align-2"
                      alt="Newimpact check icn"
                      loading="lazy"
                    />
                    Access 3 Mn+ alternative ESG datasets
                  </li>
                  <li className="mb-3">
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/check_bg.png"
                      width={16}
                      height={16}
                      className="me-2 align-2"
                      alt="NeoImpact check icn"
                      loading="lazy"
                    />
                    Deep-dive ESG analysis driven by 500+ metrics
                  </li>
                  <li className="mb-3">
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/check_bg.png"
                      width={16}
                      height={16}
                      className="me-2 align-2"
                      alt="Newimpact check icn"
                      loading="lazy"
                    />
                    Monitor data for 100+ countries
                  </li>
                </ul>
              </div>
              <div
                className="mt-4"
                style={{ filter: "grayscale(100%)", opacity: "0.6" }}
              >
                <ul className="d-flex flex-nowrap gap-4">
                  <li>
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/client_logos_31.png"
                      alt="logo"
                      width={100}
                      height={18}
                      loading="lazy"
                    />
                  </li>
                  <li>
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/client_logos_32.png"
                      alt="logo"
                      width={100}
                      height={24}
                      loading="lazy"
                    />
                  </li>
                  <li>
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/client_logos_33.png"
                      alt="logo"
                      width={100}
                      height={16}
                      loading="lazy"
                    />
                  </li>
                  <li>
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/client_logos_36.png"
                      alt="logo"
                      width={100}
                      height={18}
                      loading="lazy"
                    />
                  </li>
                </ul>
              </div>
              <div
                className="mt-4"
                style={{ filter: "grayscale(100%)", opacity: "0.6" }}
              >
                <ul className="d-flex flex-nowrap gap-5">
                  <li>
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/client_logos_35.png"
                      alt="logo"
                      width={100}
                      height={15}
                      loading="lazy"
                    />
                  </li>
                  <li>
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/client_logos_38.png"
                      alt="logo"
                      height={16}
                      width={100}
                      loading="lazy"
                    />
                  </li>
                  <li>
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/client_logos_40.png"
                      width={100}
                      alt="logo"
                      height={22}
                      loading="lazy"
                    />
                  </li>
                  <li>
                    <img
                      src="https://cdn.grandviewresearch.com/horizon/images/client_logos_31.png"
                      width={100}
                      alt="logo"
                      height={18}
                      loading="lazy"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthRightContent;
